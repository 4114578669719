import React from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PdfViewer from '../pdfViewer/PdfViewer';

const TermsDIP = () => {
	return (
		<div className="max-w-5xl mx-auto w-ful px-3">
			<PdfViewer url="https://asistensi-content-delivery.s3.amazonaws.com/RD/documentos/RD+-+Acunsa+-+DIP+e+info+mediador+.pdf" />
		</div>
	);
};

export default withMobileDialog({ breakpoint: 'xs' })(TermsDIP);
