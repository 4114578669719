import React, { Component } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
	TextField,
	MenuItem,
	Radio,
	FormControlLabel
} from '@material-ui/core';
import ReactTelInput from 'react-telephone-input';
import Validations from '../utils/Validations';
import Router from 'next/router';

import { SnackBar } from '../widgets';
import {
	TextInput,
	DateInput,
	SelectSimpleOutlined,
	SelectCurrency
} from 'components/commons';
import AddonsCard from '../addons/AddonsCard';
import { getUserAddons } from '../utils/AddonUtilities';
import { surveysCompleted, getCondition } from '../utils/HealthQuestions';
import { isArray } from 'util';
import HealthSurvey from './HealthSurvey';
import CityList from 'components/utils/CityList';
import { CalculateBMI } from 'components/utils/CalculateBMI';
import { formatFloat } from '../utils/formatFloat';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const Flags = `${serverUrlImages}/images/flags.png`;

class BeneficiaryForm extends Component {
	state = {
		openAlert: false,
		messageAlert: '',
		addonsLoaded: false,
		showWarning: false,
		listAddons: [] //Update Local State (Addons not show after save-data)
	};

	async componentDidMount() {
		const {
			stepIndex,
			beneficiaries,
			index,
			saveState,
			beneficiary,
			is_profile,
			currentPlan,
			setCurrentPlan,
			isRenew
		} = this.props;
		const { addonsLoaded } = this.state;
		//Quotation Flow - Get Age
		let age = beneficiary.age;
		if (beneficiary.birth_date && !beneficiary.age) {
			age = await this._calculateAge(beneficiary.birth_date);
			beneficiaries[index].age = age;
		}

		if (
			(!is_profile &&
				beneficiary.age &&
				(!beneficiary.products || beneficiary.products?.length <= 0)) ||
			(age &&
				beneficiary?.products &&
				beneficiary.products?.length > 0 &&
				beneficiary?.products[0].questions?.length <= 0)
		) {
			const result = await this.props.getPlansByAge({
				age: beneficiary.age || age
			});
			if (result.products) beneficiaries[index].products = result.products;
		}

		if (!is_profile && beneficiary.product_id) {
			const productType =
				beneficiary.product_type && typeof beneficiary.product_type === 'string'
					? JSON.parse(beneficiary.product_type)
					: beneficiary.product_type;
			const findProduct = beneficiary?.products?.find(
				(product) => product._id === productType._id
			);
			beneficiaries[index].product_type = productType;
			beneficiaries[index].questions = findProduct?.questions;
			if (
				!beneficiaries[index].answers ||
				beneficiaries[index]?.answers.length <= 0
			) {
				this.handleSelectPlan(beneficiaries[index].product_type, index);
			}
			if (!currentPlan[index] && isRenew)
				setCurrentPlan(index, beneficiary.product_id);
		}

		if (stepIndex === 1 && !addonsLoaded) {
			const addonsArray = [];

			this.setState({ addonsLoaded: true });
			const addons = getUserAddons(beneficiaries[index]);
			const result = await this.props.fetchAddons(beneficiaries[index]);

			await this.getBeneficiaryHealth();

			if (result?.addons) {
				beneficiaries[index].listAddons = result.addons;
				this.setState({ listAddons: result.addons });
			}

			addons.forEach((a) => {
				const aIdx = beneficiaries[index].listAddons.findIndex(
					(ap) => ap._id === (a.addon_id || a._id)
				);
				addonsArray[aIdx] = {
					addon_id: a.addon_id || a._id,
					addon_price: a.addon_price || a.payment_methods[0].tariffs[0].price
				};
			});
			beneficiaries[index].addons_id = addonsArray;
		} else if (stepIndex !== 1) this.setState({ addonsLoaded: true });

		saveState('beneficiaries', beneficiaries);
	}

	componentWillUnmount() {
		this.setState({ addonsLoaded: false });
	}

	getBeneficiaryHealth = async () => {
		const { beneficiaries, index } = this.props;
		// validacion preguntas por planes.
		const isIncomplete = surveysCompleted(beneficiaries[index].answers || []);
		if (!isIncomplete) beneficiaries[index].complete_surveys = true;
		else beneficiaries[index].complete_surveys = false;

		if (
			beneficiaries[index].feet &&
			beneficiaries[index].inches &&
			beneficiaries[index].weight
		) {
			// CALCULATE IMC
			const body_mass = CalculateBMI(
				beneficiaries[index]?.feet,
				beneficiaries[index]?.inches,
				beneficiaries[index]?.weight
			);
			beneficiaries[index].body_mass = body_mass;
			const conditionResult = await getCondition(
				{
					body_mass: beneficiaries[index].body_mass,
					birth_date: beneficiaries[index].birth_date
				},
				beneficiaries[index].answers
			);
			beneficiaries[index].goodHealth = conditionResult.condition.some(
				(c) => c.status === 'info'
			)
				? false
				: true;
		} else {
			beneficiaries[index].goodHealth = false;
		}
		this.props.saveState('beneficiaries', beneficiaries);
		return beneficiaries;
	};

	handleClose = () => this.setState({ openAlert: false });

	handleInput = async (event, index) => {
		let { name, value } = event.target;
		let beneficiaries = this.props.beneficiaries;
		beneficiaries[index][name] = value;

		if (name === 'birth_date') {
			beneficiaries[index].addons = [];
			beneficiaries[index].addons_id = [];
			const result = await this.props.fetchAddons(beneficiaries[index]);
			if (result) beneficiaries[index].listAddons = result.addons;
		}

		if (name === 'weight' || name === 'inches' || name === 'feet') {
			beneficiaries[index].addons = [];
			beneficiaries[index].addons_id = [];
			await this.getBeneficiaryHealth();
			const result = await this.props.fetchAddons(beneficiaries[index]);
			if (result) beneficiaries[index].listAddons = result.addons;
		}

		this.props.saveState('beneficiaries', beneficiaries);
		Validations.validateFieldBeneficiaries(
			beneficiaries,
			this.props.saveState,
			name,
			value,
			index
		);
		this.props.calculations();
	};

	handleInputSex = async (event, index) => {
		let { name, value } = event.target;
		let beneficiaries = this.props.beneficiaries;
		beneficiaries[index][name] = value;

		beneficiaries[index].addons = [];
		const result = await this.props.fetchAddons(beneficiaries[index]);
		if (result) beneficiaries[index].listAddons = result.addons;

		this.props.saveState('beneficiaries', beneficiaries);
		Validations.validateFieldBeneficiaries(
			beneficiaries,
			this.props.saveState,
			name,
			value,
			index
		);
		this.props.calculations();
	};

	handlePhone = (name, value, index) => {
		let beneficiaries = this.props.beneficiaries;
		beneficiaries[index][name] = value;
		this.props.saveState('beneficiaries', beneficiaries);
		Validations.validateFieldBeneficiaries(
			beneficiaries,
			this.props.saveState,
			name,
			value,
			index
		);
		this.props.calculations();
	};

	handleRadioInputQuestions = async (event, index, _id, answer) => {
		let { name, value } = event.target;
		let { beneficiaries } = this.props;
		let showSub;
		let indexValue;
		if (answer.isBoolean) {
			indexValue = 'value';
			value = value === 'true';
			if (answer.validValue === undefined) showSub = value;
			else showSub = value !== answer.validValue;
		}
		if (answer.isRange) {
			indexValue = 'valueInRange';
			value = parseFloat(value) || 0;
			showSub = value > answer.minValue && value < answer.maxValue;
			beneficiaries[index].requireMedicalVerification = value > answer.maxValue;
		}
		if (answer.isSelectMultiple) {
			indexValue = 'selectedIndex';

			if (!Array.isArray(value)) value = [];
			value = value
				.concat(value._id)
				.filter((v) => v)
				.map((v) => v._id);
			beneficiaries[index].requireMedicalVerification = value.length >= 3;

			if (value.some((v) => v === 'otros' || v === 'Otros' || v === 'OTROS')) {
				showSub = 'otros';
			} else {
				showSub = '';
			}
		}
		if (answer.isString) {
			indexValue = 'value_string';
			showSub = '';
		}

		const setAnswerValue = (subMenuItems, id, current, top) => {
			if (!subMenuItems) return;
			if (subMenuItems.length === 0) return;

			for (let i = 0; i < subMenuItems.length; i += 1) {
				if (subMenuItems[i].case === undefined) {
					if (subMenuItems[i].questionID === id) {
						if (subMenuItems[i].surveys?.length > 0) top = current;
						subMenuItems[i][indexValue] = value;
						subMenuItems[i].showSub = showSub.toString();
					} else if (current > top) {
						subMenuItems[i].value = '';
						delete subMenuItems[i].showSub;
						delete subMenuItems[i].valueInRange;
						delete subMenuItems[i].selectedIndex;
						delete subMenuItems[i].value_string;
					}
				}
				setAnswerValue(subMenuItems[i].surveys, id, ++current, top);
			}
		};
		setAnswerValue(beneficiaries[index].answers, _id, 0, 999);
		const isIncomplete = surveysCompleted(beneficiaries[index][name]);
		if (
			isIncomplete ||
			beneficiaries[index].body_mass ||
			beneficiaries[index].weight ||
			beneficiaries[index].feet ||
			beneficiaries[index].inches
		)
			beneficiaries[index].goodHealth = false;

		if (!isIncomplete) {
			beneficiaries[index].complete_surveys = true;
			if (beneficiaries[index].answers?.length > 0) {
				if (beneficiaries[index].body_mass && beneficiaries[index].birth_date) {
					await this.getBeneficiaryHealth();
					// beneficiaries[index].addons = [];
					// beneficiaries[index].addons_id = [];
					this.cleanAddonsHealth();
					const result = await this.props.fetchAddons(beneficiaries[index]);
					if (result) {
						beneficiaries[index].listAddons = result.addons;
						this.props.saveState('beneficiaries', beneficiaries);
					}
				}
			}
		} else {
			beneficiaries[index].goodHealth = false;
		}

		// const warning = await handleHealthWaning(beneficiaries[index][name]);
		// beneficiaries[index].show_warning = (warning) ? true : false;
		this.props.saveState('beneficiaries', beneficiaries);
		await this.props.calculations();
	};

	handleCheckbox = (event, index) => {
		let { name, checked } = event.target;
		let beneficiaries = this.props.beneficiaries;
		beneficiaries[index][name] = checked;
		this.props.saveState('beneficiaries', beneficiaries);
		Validations.validateFieldBeneficiaries(
			beneficiaries,
			this.props.saveState,
			name,
			checked,
			index
		);
		this.props.calculations();
	};

	setQuestionId = (questions, surveyID, questionID) => {
		if (!questions) return questions;
		return questions.map((question) => {
			if (question.case === undefined && question.survey === undefined) {
				return {
					...question,
					value: '',
					surveys: this.setQuestionId(question.surveys, surveyID, question._id),
					surveyID: surveyID,
					questionID: question._id
				};
			}
			return {
				case: question.case,
				surveys: this.setQuestionId(question.survey, surveyID, questionID)
			};
		});
	};

	handleSelectPlan = async (product, index) => {
		let { beneficiaries, showQuestions, isRenew, updatePlan, currentPlan } =
			this.props;
		beneficiaries[index].product_type = product;
		beneficiaries[index].product_id = product._id;
		beneficiaries[index].product_price = product.product_price;
		beneficiaries[index].total = product.product_price;
		beneficiaries[index].questions = product.questions;
		beneficiaries[index].answers = this.setQuestionId(
			product.questions,
			product.surveyID
		);

		// if (isRenew && updatePlan === false) showQuestions();
		if (isRenew && updatePlan === false && currentPlan[index] !== product._id)
			showQuestions();
		else if (updatePlan && currentPlan[index] === product._id)
			showQuestions(false);

		beneficiaries[index].addons_id = [];
		beneficiaries[index].addons = [];

		beneficiaries[index].goodHealth = false; //Re-start health

		const result = await this.props.fetchAddons(beneficiaries[index]);
		if (result) beneficiaries[index].listAddons = result.addons;

		this.props.saveState('beneficiaries', beneficiaries);
		this.props.calculations();
	};

	handleBirthDate = async (date, index) => {
		let beneficiaries = this.props.beneficiaries;
		beneficiaries[index].birth_date = date;
		await this.props.saveState('beneficiaries', beneficiaries);
		if (date && date instanceof Date && !isNaN(date.valueOf())) {
			const age = this._calculateAge(date);
			beneficiaries[index].age = age;
			setTimeout(async () => {
				const result = await this.props.getPlansByAge({ age });
				if (result.products) beneficiaries[index].products = result.products;
				if (result.products && beneficiaries[index].product_id) {
					const productType = result.products.find(
						(p) => beneficiaries[index].product_id === p._id
					);
					this.handleSelectPlan(productType, index);
				}
				if (result.success === false) this.setState({ ...result });
				this.props.saveState('beneficiaries', beneficiaries);
			}, 1);
		}

		Validations.validateFieldBeneficiaries(
			beneficiaries,
			this.props.saveState,
			'birth_date',
			date,
			index
		);
		this.props.calculations();
	};

	_calculateAge(birthday) {
		let today = new Date();
		let birthDate = new Date(birthday);
		let age = today.getFullYear() - birthDate.getFullYear();
		let monthToday = today.getMonth() + 1;
		let monthBirthDate = birthDate.getMonth() + 1;
		let m = monthToday - monthBirthDate;
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	cleanAddonsHealth = () => {
		const { beneficiaries, index } = this.props;
		if (!beneficiaries[index].goodHealth) {
			beneficiaries[index].addons = getUserAddons(beneficiaries[index]);
			if (!beneficiaries[index].addons_id) beneficiaries[index].addons_id = [];
			const addonsHealth = beneficiaries[index].addons
				?.filter((a) => a.depends_on_health)
				.map((a) => a._id);
			beneficiaries[index].addons = beneficiaries[index].addons.filter(
				(a) => !addonsHealth.includes(a._id)
			);
			beneficiaries[index].addons_id = beneficiaries[index].addons_id.filter(
				(a) => !addonsHealth.includes(a.addon_id || a)
			);
			this.props.saveState('beneficiaries', beneficiaries);
		}
	};

	handleSelectAddon = (flag, value, idx) => {
		const { beneficiaries, index } = this.props;
		if (beneficiaries[index] && !beneficiaries[index].addons_id)
			beneficiaries[index].addons_id = [];
		if (beneficiaries[index]) {
			beneficiaries[index].addons = getUserAddons(beneficiaries[index]);
		}
		const idxToDlt = beneficiaries[index].addons_id.findIndex(
			(a) => (a?.addon_id || a) === value._id
		);

		if (flag && idxToDlt < 0) {
			beneficiaries[index].addons.push(value);
			beneficiaries[index].addons_id.push({
				addon_id: value._id,
				addon_price: value.payment_methods[0].tariffs[0].price
			});
		} else if (!flag && idxToDlt >= 0) {
			beneficiaries[index].addons = beneficiaries[index].addons.filter(
				(a) => a._id !== value._id
			);
			beneficiaries[index].addons_id = beneficiaries[index].addons_id.filter(
				(a) => (a?.addon_id || a) !== value._id
			);
		}
		this.props.saveState('beneficiaries', beneficiaries);
		this.setState({ messageAlert: '' });
	};

	render() {
		const {
			beneficiary,
			index,
			is_profile,
			isAlly,
			beneficiaries,
			isRenew,
			updatePlan,
			isAdminOrAlly,
			editMember,
			is_ally_client,
			isoCode,
			currentPlan
		} = this.props;
		const isMobile = Router?.router?.asPath?.includes('/m');

		return (
			<div className="form-group w-full" key={index}>
				<div className="flex flex-wrap beneficiary-form">
					{!(beneficiary.kinship === 'TITULAR' && is_profile) && (
						<TextField
						select
						label="Parentesco"
						name="kinship"
						value={beneficiary.kinship}
						onChange={(event) => this.handleInput(event, index)}
						variant="outlined"
						error={true}
						required={true}
						className={`wInputThree ${
							beneficiary.kinship === 'default' && 'default'
						}`}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						InputLabelProps={{
							classes: {
								// root: (!beneficiary.kinship || beneficiary.kinship === 'default') ? 'cssLabel' : (beneficiary.formErrors?.kinship) ? 'cssLabelError' : 'cssLabelSuccess',
								root:
									beneficiary.kinship && !beneficiary.formErrors?.kinship
										? 'cssLabel'
										: (!beneficiary.kinship &&
												beneficiary.formErrors?.kinship) ||
										  beneficiary.formErrors?.kinship
										? 'cssLabelError'
										: 'cssLabelSuccess'
							},
							shrink: true
						}}
						InputProps={{
							classes: {
								// notchedOutline: (!beneficiary.kinship || beneficiary.kinship === 'default') ? 'cssInput' : (beneficiary.formErrors?.kinship) ? 'cssInputError': 'cssInputSuccess'
								notchedOutline:
									!beneficiary.kinship && !beneficiary.formErrors?.kinship
										? 'cssInput'
										: (!beneficiary.kinship &&
												beneficiary.formErrors?.kinship) ||
										  beneficiary.formErrors?.kinship
										? 'cssInputError'
										: 'cssInputSuccess'
							}
						}}
						SelectProps={{
							IconComponent: KeyboardArrowDownIcon
						}}
						helperText={beneficiary.formErrors?.kinship}
						margin="normal"
						// disabled={is_profile === true ? true : false}
						disabled={
							(is_profile && !editMember) || beneficiary.NotEditCustomer
						}
					>
						<MenuItem value="default" hidden disabled>
							Selecciona el parentesco
						</MenuItem>
						<MenuItem value="Hijo">Hijo/a</MenuItem>
						{beneficiaries.length > 1 &&
							!beneficiaries.find((b) => b.kinship === 'Conyugue') && (
								<MenuItem value="Conyugue">Cónyuge</MenuItem>
							)}
						{(beneficiaries.length === 1 ||
							beneficiary.kinship === 'Conyugue') && (
							<MenuItem value="Conyugue">Cónyuge</MenuItem>
						)}
						{(!beneficiaries.find((b) => b.kinship === 'Madre') ||
							beneficiary.kinship === 'Madre') && (
							<MenuItem value="Madre">Madre</MenuItem>
						)}
						{(!beneficiaries.find((b) => b.kinship === 'Padre') ||
							beneficiary.kinship === 'Padre') && (
							<MenuItem value="Padre">Padre</MenuItem>
						)}

						<MenuItem value="Hermano">Hermano/a</MenuItem>
						<MenuItem value="Abuelo">Abuelo/a</MenuItem>
						{(beneficiary.kinship !== '' || beneficiary.kinship === 'Otro') &&
							beneficiary.kinship !== 'Hijo' &&
							beneficiary.kinship !== 'Conyugue' &&
							beneficiary.kinship !== 'Madre' &&
							beneficiary.kinship !== 'Padre' &&
							beneficiary.kinship !== 'Hermano' &&
							beneficiary.kinship !== 'default' && (
								<MenuItem
									value={
										beneficiary.kinship === 'Otro'
											? beneficiary.other_kinship
											: beneficiary.kinship
									}
								>
									{beneficiary.kinship === 'Otro'
										? beneficiary.other_kinship
										: beneficiary.kinship}
								</MenuItem>
							)}
						<MenuItem value="Otro">Otro</MenuItem>
					</TextField>
					)}
					{beneficiary.kinship === 'Otro' && (
						<TextInput
							name="other_kinship"
							label="Especifique parentesco"
							placeholder="Parentesco"
							helperText={beneficiary.formErrors?.other_kinship}
							errors={beneficiary.formErrors?.other_kinship}
							value={beneficiary.other_kinship}
							addClass="wInputThree"
							onChange={(event) => this.handleInput(event, index)}
							required={isAlly === true ? false : true}
							FormHelperTextProps={{
								classes: {
									root: is_profile === true ? '' : 'cssHelperTextError'
								}
							}}
							disabled={beneficiary.NotEditCustomer}
						/>
					)}

					<TextInput
						name="first_name"
						label="Nombres"
						placeholder="Nombres"
						helperText={beneficiary.formErrors?.first_name}
						errors={beneficiary.formErrors?.first_name}
						value={beneficiary.first_name}
						addClass="wInputThree"
						onChange={(event) => this.handleInput(event, index)}
						required={isAlly === true ? false : true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={beneficiary.NotEditCustomer}
					/>

					<TextInput
						name="last_name"
						label="Apellidos"
						placeholder="Apellidos"
						helperText={beneficiary.formErrors?.last_name}
						errors={beneficiary.formErrors?.last_name}
						value={beneficiary.last_name}
						addClass="wInputThree"
						onChange={(event) => this.handleInput(event, index)}
						required={isAlly === true ? false : true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={beneficiary.NotEditCustomer}
					/>

					<DateInput
						clearable={true}
						name="birth_date"
						label="Fecha de Nacimiento"
						placeholder="Fecha de Nacimiento"
						format="dd MMM yyyy"
						maxDate={new Date()}
						helperText={beneficiary.formErrors?.birth_date}
						errors={beneficiary.formErrors?.birth_date}
						value={beneficiary.birth_date}
						addClass="wInputThree"
						onChange={(date) => this.handleBirthDate(date, index)}
						disabled={
							is_profile === true || isRenew || beneficiary.NotEditCustomer
						}
					/>

					{/* Identifier... */}
					<TextField
						select
						label="Tipo de Documento"
						name="nationality"
						value={beneficiary.nationality}
						onChange={(event) => this.handleInput(event, index)}
						variant="outlined"
						error={true}
						required={true}
						className={`field-responsive wInputThree m-t25 ${
							beneficiary.nationality === 'default' && 'default'
						}`}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						InputLabelProps={{
							classes: {
								// root: (!beneficiary.nationality || beneficiary.nationality === 'default') ? 'cssLabel' : (beneficiary.formErrors?.nationality) ? 'cssLabelError' : 'cssLabelSuccess',
								root:
									beneficiary.nationality &&
									!beneficiary.formErrors?.nationality
										? 'cssLabel'
										: (!beneficiary.nationality &&
												beneficiary.formErrors?.nationality) ||
										  beneficiary.formErrors?.nationality
										? 'cssLabelError'
										: 'cssLabelSuccess'
							},
							shrink: true
						}}
						InputProps={{
							classes: {
								// notchedOutline: (!beneficiary.nationality || beneficiary.nationality === 'default') ? 'cssInput' : (beneficiary.formErrors?.nationality) ? 'cssInputError': 'cssInputSuccess'
								notchedOutline:
									!beneficiary.nationality &&
									!beneficiary.formErrors?.nationality
										? 'cssInput'
										: (!beneficiary.nationality &&
												beneficiary.formErrors?.nationality) ||
										  beneficiary.formErrors?.nationality
										? 'cssInputError'
										: 'cssInputSuccess'
							}
						}}
						SelectProps={{
							IconComponent: KeyboardArrowDownIcon
						}}
						helperText={beneficiary.formErrors?.nationality}
						margin="normal"
						disabled={beneficiary.NotEditCustomer}
					>
						<MenuItem value="default" hidden disabled>
							Selecciona el tipo de documento
						</MenuItem>
						{beneficiary.age < 18 && (
							<MenuItem value="Menor de Edad">Menor de Edad</MenuItem>
						)}
						<MenuItem value="Cédula">Cédula</MenuItem>
						<MenuItem value="Pasaporte">Pasaporte</MenuItem>
					</TextField>

					<TextInput
						name="dni"
						label={
							beneficiary.nationality === 'Menor de Edad'
								? 'Número de Documento responsable'
								: 'Número de Documento'
						}
						placeholder="Número de Documento"
						helperText={beneficiary.formErrors?.dni}
						errors={beneficiary.formErrors?.dni}
						value={beneficiary.dni}
						addClass="wInputThree"
						onChange={(event) => this.handleInput(event, index)}
						required={isAlly === true ? false : true}
						disabled={
							is_profile === true || isRenew || beneficiary.NotEditCustomer
						}
						FormHelperTextProps={{
							classes: {
								root: is_profile === true || isRenew ? '' : 'cssHelperTextError'
							}
						}}
					/>

					<TextField
						select
						label="Sexo"
						name="sex"
						value={beneficiary.sex}
						onChange={(event) => this.handleInputSex(event, index)}
						variant="outlined"
						error={true}
						required={true}
						className={`field-responsive wInputThree m-t25 ${
							beneficiary.sex === 'default' && 'default'
						}`}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						InputLabelProps={{
							classes: {
								// root: (!beneficiary.sex || beneficiary.sex === 'default') ? 'cssLabel' : (beneficiary.formErrors?.sex) ? 'cssLabelError' : 'cssLabelSuccess',
								root:
									beneficiary.sex && !beneficiary.formErrors?.sex
										? 'cssLabel'
										: (!beneficiary.sex && beneficiary.formErrors?.sex) ||
										  beneficiary.formErrors?.sex
										? 'cssLabelError'
										: 'cssLabelSuccess'
							},
							shrink: true
						}}
						InputProps={{
							classes: {
								// notchedOutline: (!beneficiary.sex || beneficiary.sex === 'default') ? 'cssInput' : (beneficiary.formErrors?.sex) ? 'cssInputError': 'cssInputSuccess'
								notchedOutline:
									!beneficiary.sex && !beneficiary.formErrors?.sex
										? 'cssInput'
										: (!beneficiary.sex && beneficiary.formErrors?.sex) ||
										  beneficiary.formErrors?.sex
										? 'cssInputError'
										: 'cssInputSuccess'
							}
						}}
						SelectProps={{
							IconComponent: KeyboardArrowDownIcon
						}}
						helperText={beneficiary.formErrors?.sex}
						margin="normal"
						disabled={beneficiary.NotEditCustomer}
					>
						<MenuItem value="default" hidden disabled>
							Selecciona el sexo
						</MenuItem>
						<MenuItem value="F">Femenino</MenuItem>
						<MenuItem value="M">Masculino</MenuItem>
					</TextField>

					<TextInput
						name="occupation"
						label="Ocupación"
						placeholder="Ocupación"
						helperText={beneficiary.formErrors?.occupation}
						errors={beneficiary.formErrors?.occupation}
						value={beneficiary.occupation}
						addClass="wInputThree"
						onChange={(event) => this.handleInput(event, index)}
						required={true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={beneficiary.NotEditCustomer}
					/>

					<TextInput
						name="email"
						label="Correo Electrónico"
						placeholder="Correo Electrónico"
						helperText={beneficiary.formErrors?.email}
						errors={beneficiary.formErrors?.email}
						value={beneficiary.email}
						addClass="wInputThree"
						onChange={(event) => this.handleInput(event, index)}
						required={isAlly === true ? false : true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={beneficiary.NotEditCustomer}
					/>

					<ReactTelInput
						required={isAlly === true ? false : true}
						onChange={(telNumber) =>
							this.handlePhone('phone_one', telNumber, index)
						}
						defaultCountry="do"
						value={beneficiary.phone_one}
						flagsImagePath={Flags}
						// className={(!beneficiary.phone_one) ? 'telInput' : (beneficiary.formErrors?.phone_one) ? 'telInputError' : "telInput"}
						className={
							beneficiary.phone_one && !beneficiary.formErrors?.phone_one
								? 'telInput'
								: (!beneficiary.phone_one &&
										beneficiary.formErrors?.phone_one) ||
								  beneficiary.formErrors?.phone_one
								? 'telInputError'
								: 'telInput'
						}
						disabled={beneficiary.NotEditCustomer}
					/>

					<ReactTelInput
						required={isAlly === true ? false : true}
						onChange={(telNumber) =>
							this.handlePhone('phone_two', telNumber, index)
						}
						defaultCountry="do"
						value={beneficiary.phone_two}
						flagsImagePath={Flags}
						// className={(!beneficiary.phone_two) ? 'telInput' : (beneficiary.formErrors?.phone_two) ? 'telInputError' : "telInput"}
						className={
							beneficiary.phone_two && !beneficiary.formErrors?.phone_two
								? 'telInput otherLabel'
								: (!beneficiary.phone_two &&
										beneficiary.formErrors?.phone_two) ||
								  beneficiary.formErrors?.phone_two
								? 'telInputError otherLabel'
								: 'telInput otherLabel'
						}
						disabled={beneficiary.NotEditCustomer}
					/>

					<TextField
						select
						name="country"
						label="País"
						placeholder="País"
						helperText={beneficiary.formErrors?.country}
						variant="outlined"
						error={true}
						className={`field-responsive wInputThree m-t25 ${
							beneficiary.country === 'default' && 'default'
						}`}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						InputLabelProps={{
							classes: {
								// root: (!beneficiary.country || beneficiary.country === 'default') ? 'cssLabel' : (beneficiary.formErrors?.country) ? 'cssLabelError' : 'cssLabelSuccess',
								root:
									beneficiary.country && !beneficiary.formErrors?.country
										? 'cssLabel'
										: (!beneficiary.country &&
												beneficiary.formErrors?.country) ||
										  beneficiary.formErrors?.country
										? 'cssLabelError'
										: 'cssLabelSuccess'
							},
							shrink: true
						}}
						InputProps={{
							classes: {
								// notchedOutline: (!beneficiary.country || beneficiary.country === 'default') ? 'cssInput' : (beneficiary.formErrors?.country) ? 'cssInputError': 'cssInputSuccess'
								notchedOutline:
									!beneficiary.country && !beneficiary.formErrors?.country
										? 'cssInput'
										: (!beneficiary.country &&
												beneficiary.formErrors?.country) ||
										  beneficiary.formErrors?.country
										? 'cssInputError'
										: 'cssInputSuccess'
							}
						}}
						SelectProps={{
							IconComponent: KeyboardArrowDownIcon
						}}
						margin="normal"
						value={beneficiary.country}
						required={isAlly === true ? false : true}
						onChange={(event) => this.handleInput(event, index)}
						// disabled={is_profile === true ? true : false}
						disabled={
							(is_profile && !editMember) || beneficiary.NotEditCustomer
						}
					>
						<MenuItem value="default" hidden disabled>
							Selecciona el país
						</MenuItem>
						{['República Dominicana'].map((country) => (
							<MenuItem key={country} value={country}>
								{country}
							</MenuItem>
						))}
					</TextField>

					<TextField
						select
						name="city"
						label="Provincia"
						placeholder="Provincia"
						helperText={beneficiary.formErrors?.city}
						variant="outlined"
						error={true}
						className={`field-responsive wInputThree m-t25 ${
							beneficiary.city === 'default' && 'default'
						}`}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						InputLabelProps={{
							classes: {
								// root: (!beneficiary.city || beneficiary.city === 'default') ? 'cssLabel' : (beneficiary.formErrors?.city) ? 'cssLabelError' : 'cssLabelSuccess',
								root:
									beneficiary.city && !beneficiary.formErrors?.city
										? 'cssLabel'
										: (!beneficiary.city && beneficiary.formErrors?.city) ||
										  beneficiary.formErrors?.city
										? 'cssLabelError'
										: 'cssLabelSuccess'
							},
							shrink: true
						}}
						InputProps={{
							classes: {
								// notchedOutline: (!beneficiary.city || beneficiary.city === 'default') ? 'cssInput' : (beneficiary.formErrors?.city) ? 'cssInputError': 'cssInputSuccess'
								notchedOutline:
									!beneficiary.city && !beneficiary.formErrors?.city
										? 'cssInput'
										: (!beneficiary.city && beneficiary.formErrors?.city) ||
										  beneficiary.formErrors?.city
										? 'cssInputError'
										: 'cssInputSuccess'
							}
						}}
						SelectProps={{
							IconComponent: KeyboardArrowDownIcon
						}}
						margin="normal"
						value={beneficiary.city}
						required={isAlly === true ? false : true}
						onChange={(event) => this.handleInput(event, index)}
						disabled={beneficiary.NotEditCustomer}
					>
						<MenuItem value="default" hidden disabled>
							Selecciona la provincia
						</MenuItem>
						{CityList.map(({ name, value }) => (
							<MenuItem key={name} value={value}>
								{name}
							</MenuItem>
						))}
					</TextField>

					<TextInput
						name="city_two"
						label="Ciudad"
						placeholder="Ciudad"
						helperText={beneficiary.formErrors?.city_two}
						errors={beneficiary.formErrors?.city_two}
						value={beneficiary?.city_two}
						addClass="wInputThree"
						onChange={(event) => this.handleInput(event, index)}
						required={true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={beneficiary.NotEditCustomer}
					/>

					<TextInput
						name="address"
						label="Dirección residencia"
						placeholder="Dirección residencia"
						helperText={beneficiary.formErrors?.address}
						errors={beneficiary.formErrors?.address}
						value={beneficiary.address}
						addClass="wInputThree"
						onChange={(event) => this.handleInput(event, index)}
						required={isAlly === true ? false : true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={beneficiary.NotEditCustomer}
					/>
					{is_profile !== true && (
						<div className="select-plan-cont px-5 hidden md:flex">
							<span className={`text-gray-800 ${isMobile ? 'text-xs' : 'text-base font-bold leading-relaxed'}`}>
								Planes de Salud
							</span>
							<div className="flex justify-between w-2/5">
								{isArray(beneficiary.products) &&
									beneficiary.birth_date &&
									beneficiary.products.map((product, i) => (
										<FormControlLabel
											key={i}
											name="product"
											className="m-r10"
											value={String(product._id)}
											control={
												<Radio
													value={String(product._id)}
													className={
														beneficiary.product_id === product._id
															? 'checkBoxActive'
															: 'checkBox'
													}
													checked={
														beneficiary.product_id === product._id
															? true
															: false
													}
												/>
											}
											onChange={() => this.handleSelectPlan(product, index)}
											label={
												<span
													className={`text-gray-800 text-normal ${
														beneficiary.product_id === product._id
															? 'font-bold'
															: 'font-normal'
													}`}
												>
													{product.name}
												</span>
											}
											labelPlacement="end"
											disabled={beneficiary.NotEditCustomer}
										/>
									))}
								{!beneficiary.birth_date && (
									<span className="text-gray-600 text-normal my-2">
										Debe ingresar su fecha de nacimiento
									</span>
								)}
							</div>
							<span className="text-normal leading-relaxed mr-2">
								{beneficiary.total && beneficiary.product_type ? (
									<span className={`text-gray-800 ${isMobile ? 'text-xs' : 'text-base'}`}>
										{isoCode && isoCode.toUpperCase()}{' '}
										{formatFloat(beneficiary?.product_type?.currency[isoCode])}{' '}
										anual
									</span>
								) : (
									<span className={`text-gray-600 ${isMobile ? 'text-xs' : ''}`}>
										{isoCode && isoCode.toUpperCase()} 0
									</span>
								)}
							</span>
							<span className="ml-2">
								<SelectCurrency hideFlag={true} />
							</span>
						</div>
					)}
					{/* RESPONSIVE PLANS */}
					{is_profile !== true && (
						<div className="w-full border border-gray-300 mt-6 mb-2 bg-white md:hidden">
							<div className="flex items-center bg-white border-b border-gray-300 min-h-50p px-5">
								<span className={`text-gray-800 ${isMobile ? 'text-xs' : 'text-normal'}`}>
									Selecciona el plan
								</span>
							</div>
							<div className="text-gray-800 text-normal flex justify-between p-5 border-b border-gray-300">
								<span className="font-bold">Plan</span>
								<div className="flex flex-col items-end">
									{isArray(beneficiary.products) &&
										beneficiary.products.map((product, i) => (
											<span key={i} className="m-b5">
												<FormControlLabel
													key={i}
													name="product"
													value={String(product._id)}
													control={
														<Radio
															value={String(product._id)}
															className={
																beneficiary.product_id === product._id
																	? 'checkBoxActive'
																	: 'checkBox'
															}
															checked={
																beneficiary.product_id === product._id
																	? true
																	: false
															}
														/>
													}
													onChange={() => this.handleSelectPlan(product, index)}
													label={
														<span
															className={`text-gray-800 text-normal ${
																beneficiary.product_id === product._id
																	? 'font-bold'
																	: 'font-normal'
															}`}
														>
															{product.name}
														</span>
													}
													labelPlacement="start"
													disabled={beneficiary.NotEditCustomer}
												/>
											</span>
										))}
								</div>
							</div>
							<div className="text-gray-800 text-normal flex items-center justify-between px-5 min-h-50p">
								<span className="font-bold">Valor</span>
								<span>
									{beneficiary.total && beneficiary.product_type ? (
										<span className={`text-gray-800 ${isMobile ? 'text-xs' : 'text-base'}`}>
											{isoCode && isoCode.toUpperCase()}{' '}
											{formatFloat(
												beneficiary?.product_type?.currency[isoCode]
											)}{' '}
											anual
										</span>
									) : (
										<span className={`text-gray-600 ${isMobile ? 'text-xs' : ''}`}>
											{isoCode && isoCode.toUpperCase()} 0
										</span>
									)}
									<span className="ml-3">
										<SelectCurrency hideFlag={true} />
									</span>
								</span>
							</div>
						</div>
					)}
					{beneficiary.product_id &&
						is_profile !== true &&
						((!isRenew && updatePlan === true) ||
							(isRenew && currentPlan[index] !== beneficiary.product_id)) &&
						!beneficiary.NotEditCustomer &&
						(!isAdminOrAlly || (isAdminOrAlly && is_ally_client)) && (
							<HealthSurvey
								questions={beneficiary?.questions}
								parent={beneficiary}
								handleRadioInputQuestions={this.handleRadioInputQuestions}
								index={index}
								isAlly={isAlly}
								showWarning={this.state.showWarning}
							/>
						)}
					{beneficiary.product_id && is_profile !== true && (
						<div className="flex flex-col mb-2 mt-10 w-full">
							<div className="flex flex-wrap">
								<SelectSimpleOutlined
									name="feet"
									label="Estatura"
									value={beneficiary.feet}
									placeholder="Pies"
									simpleList={['0', '1', '2', '3', '4', '5', '6', '7', '8']}
									addClass={`wInputThree ${
										beneficiary.feet === 'default' && 'default'
									}`}
									helperText={beneficiary.formErrors?.feet}
									errors={beneficiary.formErrors?.feet}
									onChange={(event) => this.handleInput(event, index)}
									required={true}
									FormHelperTextProps={{
										classes: {
											root: is_profile === true ? '' : 'cssHelperTextError'
										}
									}}
									prefix="(ft)"
									disabled={beneficiary.NotEditCustomer}
								/>

								<SelectSimpleOutlined
									name="inches"
									label="Estatura"
									value={beneficiary.inches}
									placeholder="Pulgadas"
									simpleList={[
										'0',
										'1',
										'2',
										'3',
										'4',
										'5',
										'6',
										'7',
										'8',
										'9',
										'10',
										'11'
									]}
									addClass={`wInputThree ${
										beneficiary.inches === 'default' && 'default'
									}`}
									helperText={beneficiary.formErrors?.inches}
									errors={beneficiary.formErrors?.inches}
									onChange={(event) => this.handleInput(event, index)}
									required={true}
									FormHelperTextProps={{
										classes: {
											root: is_profile === true ? '' : 'cssHelperTextError'
										}
									}}
									prefix="(in)"
									disabled={beneficiary.NotEditCustomer}
								/>

								<TextInput
									type="number"
									name="weight"
									label="Peso"
									placeholder="Peso"
									helperText={beneficiary.formErrors?.weight}
									errors={beneficiary.formErrors?.weight}
									error={beneficiary.formErrors?.weight ? true : false}
									value={beneficiary.weight}
									addClass="wInputThree"
									onChange={(event) => this.handleInput(event, index)}
									FormHelperTextProps={{
										classes: {
											root: is_profile === true ? '' : 'cssHelperTextError'
										}
									}}
									required={isAlly === true ? false : true}
									prefix="(lb)"
									disabled={beneficiary.NotEditCustomer}
								/>
							</div>
						</div>
					)}
					{/* ADDONS */}
					{beneficiary?.listAddons?.length > 0 && (
						<AddonsCard
							isoCode={isoCode}
							user={beneficiary}
							handleSelectAddon={this.handleSelectAddon}
						/>
					)}
				</div>
				<SnackBar
					openAlert={this.state.openAlert}
					messageAlert={this.state.messageAlert}
					handleClose={this.handleClose}
				/>
			</div>
		);
	}
}

export default BeneficiaryForm;
