import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { TextInput } from 'components/commons';
class CardForm extends Component {
	state = {
		cvc: this.props.payment?.client_card?.cvc || '',
		expiry: this.props.payment?.client_card?.exp || '',
		focus: '',
		name: this.props.payment?.client_card?.name || this.props.name,
		number: this.props.payment?.client_card?.number || '',
		cardType: null,
		error: null
	};

	componentDidUpdate(prevProps) {
		if (
			this.props.name !== prevProps.name ||
			this.state.name !== this.props.name
		) {
			this.setState({ name: this.props.name });
		}
	}

	handleInputChange = async (e) => {
		let { payment } = this.props;
		const { name, value } = e.target;
		if (name === 'expiry') {
			payment.client_card.exp = value;
		} else {
			payment.client_card[name] = value;
		}
		if (name === 'number') {
			payment.cardType = this.state.cardType;
			this.validateCardNumber(value);
		}
		this.setState({ [name]: value });
		await this.props.handleSave('payment', payment);
	};

	validateCardNumber = (value) => {
		let validate = /^([0-9]{14,16})$/.test(value);
		validate
			? this.setState({ error: null })
			: this.setState({ error: 'Ingrese un número de tarjeta válido' });
	};

	handleInputFocus = (e) => {
		this.setState({ focus: e.target.name });
	};

	render() {
		const { cvc, expiry, name, number, focus, cardType, error } = this.state;

		if (this.props.payment && cardType) this.props.payment.cardType = cardType;

		return (
			<form className="flex text-gray-800">
				<style>
					{`
            .card-input-class {
              background-color: white;
              padding: 18.5px 14px;
              border: 1px #e6ebf1 solid;
              border-radius: 5px;
            }
            input:focus-visible {
              outline: -webkit-focus-ring-color auto 0px;
            }
          `}
				</style>
				<div
					id="PaymentForm"
					className="flex flex-col md:flex-row md:items-center w-full"
				>
					<Cards
						cvc={cvc}
						expiry={expiry}
						focused={focus}
						name={name}
						number={number}
						callback={(cardType) => this.setState({ cardType })}
					/>
					<form className="flex flex-wrap mt-4 md:mt-0 md:ml-4">
						<div className="wInputFull mt-0 flex flex-col">
							<TextInput
								name="number"
								label="Número de tarjeta"
								placeholder="1234 1234 1234 1234"
								value={number}
								addClass="wInputFull mx-0"
								helperText={error}
								errors={error}
								onFocus={this.handleInputFocus}
								onChange={this.handleInputChange}
								required={true}
							/>
						</div>
						<div className="wInputTwo mt-0 flex flex-col">
							<label>
								<span style={{ color: '#9aabb5', fontSize: '13px' }}>
									Fecha de expiración{' '}
									<span style={{ color: '#f44336' }}>*</span>
								</span>
							</label>
							<InputMask
								mask="99/99"
								value={expiry}
								name="expiry"
								label="Fecha de expiración"
								placeholder="MM/AA"
								onFocus={this.handleInputFocus}
								onChange={this.handleInputChange}
								className="card-input-class"
							/>
						</div>
						<div className="wInputTwo mt-0 flex flex-col">
							<label>
								<span style={{ color: '#9aabb5', fontSize: '13px' }}>
									CVC <span style={{ color: '#f44336' }}>*</span>
								</span>
							</label>
							<InputMask
								mask={cardType?.issuer === 'amex' ? '9999' : '999'}
								value={cvc}
								name="cvc"
								label="CVC"
								placeholder="CVC"
								onFocus={this.handleInputFocus}
								onChange={this.handleInputChange}
								className="card-input-class"
							/>
						</div>
					</form>
				</div>
			</form>
		);
	}
}

export default CardForm;
