import React, { Component } from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import TermsConditionAddonLife from './TermsConditionAddonLife';
import DOMPurify from 'isomorphic-dompurify';

const sanitizer = DOMPurify.sanitize;
class TermsTituAddonDialog extends Component {
	render() {
		const { fullScreen, open, handleClose, termsCondition } = this.props;

		return (
			<Dialog
				open={open}
				maxWidth="md"
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
				fullScreen={fullScreen}
			>
				<DialogTitle className="text-gray-900 font-bold">
					<img
						src={`/static/images/Asistensi_logotipo.svg`}
						alt="asistensi_logotipo"
						width="160px"
					/>
				</DialogTitle>
				<DialogContent>
					{
						<div
							class="max-w-5xl mx-auto w-full"
							dangerouslySetInnerHTML={{ __html: sanitizer(termsCondition) }}
						></div>
					}
					<div className="flex justify-evenly my-10">
						<button
							className="secondary-button-nav mr-2"
							onClick={() => handleClose(false)}
						>
							No acepto
						</button>
						<button
							className="white-button-nav"
							onClick={() => handleClose(true)}
						>
							Acepto
						</button>
					</div>
				</DialogContent>
			</Dialog>
		);
	}
}

export default withMobileDialog({ breakpoint: 'xs' })(TermsTituAddonDialog);
