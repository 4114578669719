import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { SnackBar } from '../widgets';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

const styles = () => ({
	progress: {
		margin: '0 0 0 5px',
		color: '#fb314a;'
	}
});

const DownloadFileButton = ({
	toolTipTitle = 'Descargar documento',
	file,
	fileName,
	actions,
	classes
}) => {
	const [spinner, setSpinner] = React.useState(false);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [messageAlert, setMessageAlert] = React.useState('');

	const handleDownloadDocument = async () => {
		setSpinner(true);

		let response = await actions.downloadFile({
			file,
			fileName
		});

		if (!response.success) {
			setMessageAlert(response.messageAlert);
			setOpenAlert(true);
		}

		setSpinner(false);
	};

	return (
		<div className="flex items-center ml-2">
			<Tooltip title={toolTipTitle}>
				{spinner ? (
					<div>
						<CircularProgress size={20} className={classes.progress} />
					</div>
				) : (
					<button
						onClick={(e) => {
							e.stopPropagation();
							handleDownloadDocument(file);
						}}
						className="text-gray-800 hover:text-pink-500 duration-100 delay-75 transform hover:scale-125"
					>
						<SaveIcon className="text-2xl" />
					</button>
				)}
			</Tooltip>

			<SnackBar
				openAlert={openAlert}
				messageAlert={messageAlert}
				handleClose={() => setOpenAlert(false)}
			/>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return { actions: bindActionCreators(actions, dispatch) };
};

export default withStyles(styles)(
	connect(null, mapDispatchToProps)(DownloadFileButton)
);
