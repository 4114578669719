import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import CloseIcon from '@material-ui/icons/Close';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import HealthSurvey from '../forms/HealthSurvey';
import { surveysCompleted } from '../utils/HealthQuestions';
import TermsTituAddonDialog from '../terms/TermsTituAddonDialog';

function HealthSurveyDialog(props) {
	const [addonSelected, setAddonSelected] = useState({});
	const [complete, setComplete] = useState(false);
	const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {
		const addonTitular =
			props.titular.addons_titular && props.titular?.addons_titular?.length > 0
				? props.titular.addons_titular?.find(
						(addon) => addon.id_addon === props.addon._id
				  )
				: null;
		if (addonTitular) {
			addonTitular.questions = props.addon.questions;
			setAddonSelected(addonTitular);
			const isIncomplete = surveysCompleted(addonTitular.answers);
			setComplete(!isIncomplete);
		} else {
			const result = setQuestionId(props.addon.questions, props.addon.surveyID);
			const addon = props.addon;
			addon.answers = result;
			setAddonSelected(addon);
		}
	}, [props.addon, props.titular.addons_titular]);

	const handleClose = () => {
		props.handleClose(false, addonSelected);
	};

	const setQuestionId = (questions, surveyID, questionID) => {
		if (!questions) return questions;
		return questions.map((question) => {
			if (question.case === undefined && question.survey === undefined) {
				return {
					...question,
					value: '',
					surveys: setQuestionId(question.surveys, surveyID, question._id),
					surveyID: surveyID,
					questionID: question._id
				};
			}
			return {
				case: question.case,
				surveys: setQuestionId(question.survey, surveyID, questionID)
			};
		});
	};

	const handleRadioInputQuestions = (event, index, _id, answer) => {
		let { name, value } = event.target;
		let answersCopy = addonSelected.answers;
		let showSub;
		let indexValue;
		if (answer.isBoolean) {
			indexValue = 'value';
			value = value === 'true';
			if (answer.validValue === undefined) showSub = value;
			else showSub = value !== answer.validValue;
		}
		if (answer.isRange) {
			indexValue = 'valueInRange';
			value = parseFloat(value) || 0;
			showSub = value > answer.minValue && value < answer.maxValue;
		}
		if (answer.isSelectMultiple) {
			indexValue = 'selectedIndex';

			if (!Array.isArray(value)) value = [];
			value = value
				.concat(value._id)
				.filter((v) => v)
				.map((v) => v._id);

			if (value.some((v) => v === 'otros' || v === 'Otros' || v === 'OTROS')) {
				showSub = 'otros';
			} else {
				showSub = '';
			}
		}
		if (answer.isString) {
			indexValue = 'value_string';
			showSub = '';
		}

		/*Delete Sub-Surveys (Inside Surveys Answers for a question)*/
		const deleteInside = (subMenuItems) => {
			if (subMenuItems.surveys.length === 0) return;
			if (subMenuItems.surveys.length > 0) {
				for (let j = 0; j < subMenuItems.surveys.length; j += 1) {
					if (subMenuItems.surveys[j].surveys.length > 0)
						deleteInside(subMenuItems.surveys[j]);
					subMenuItems.surveys[j].value = '';
					delete subMenuItems.surveys[j].showSub;
					delete subMenuItems.surveys[j].valueInRange;
					delete subMenuItems.surveys[j].selectedIndex;
					delete subMenuItems.surveys[j].value_string;
				}
			}
		};

		const setAnswerValue = (subMenuItems, id, current, top) => {
			if (!subMenuItems) return;
			if (subMenuItems.length === 0) return;

			for (let i = 0; i < subMenuItems.length; i += 1) {
				if (subMenuItems[i].case === undefined) {
					if (subMenuItems[i].questionID === id) {
						if (subMenuItems[i].surveys?.length > 0) {
							top = current;
							deleteInside(subMenuItems[i]);
						}
						subMenuItems[i][indexValue] = value;
						subMenuItems[i].showSub = showSub;
					} else if (current > top) {
						break;
					}
				}
				setAnswerValue(subMenuItems[i].surveys, id, ++current, top);
			}
		};
		setAnswerValue(answersCopy, _id, 0, 999);
		const isIncomplete = surveysCompleted(answersCopy);
		if (!isIncomplete) {
			setComplete(true);
		} else {
			setComplete(false);
		}
		setAddonSelected({ ...addonSelected, answers: answersCopy });
	};

	const handleSaveAnswer = async () => {
		const isIncomplete = surveysCompleted(addonSelected.answers);
		if (!isIncomplete) {
			setAddonSelected(addonSelected);
			props.handleClose(true, addonSelected);
		}
	};

	const handleAcceptTerm = (param) => {
		setAddonSelected({ ...addonSelected, ['value_tc']: param });
	};

	const handleCloseTerms = (param) => {
		handleAcceptTerm(param);
		setShowDialog(false);
	};

	return (
		<>
			<div className="w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 z-100 flex justify-center px-4">
				<span
					className="absolute right-0 top-0 bg-pink-500 p-3 cursor-pointer hidden md:block"
					onClick={() => handleClose()}
				>
					<CloseIcon className="text-white" />
				</span>
				<div className="bg-white my-10 pt-5 h-50 max-w-6xl overflow-auto">
					{addonSelected.survey_title && (
						<div className="text-3xl text-purple-500 font-bold px-10 pt-5 flex flex-col">
							<span
								className="pb-3 cursor-pointer block md:hidden flex justify-end"
								onClick={() => handleClose()}
							>
								<CloseIcon className="text-purple-500" />
							</span>
							{addonSelected.survey_title}
						</div>
					)}
					<HealthSurvey
						questions={addonSelected.questions}
						parent={props.titular}
						handleRadioInputQuestions={handleRadioInputQuestions}
						answers={addonSelected.answers}
						classes="p-12 mb-0"
						questionClass="mb-0"
					/>
					<div className="text-3xl text-purple-500 font-bold w-full p-3 px-5 md:px-14 mb-0 mt-0">
						<FormControlLabel
							label={
								<span className="text-sm flex items-center w-full">
									<span>
										Acepto los
										<a
											className="text-purple-500 mx-2 font-bold underline"
											onClick={() => setShowDialog(true)}
										>
											términos y condiciones
										</a>
									</span>
								</span>
							}
							control={
								<Checkbox
									checked={
										addonSelected.value_tc ? addonSelected.value_tc : false
									}
									onClick={() =>
										handleAcceptTerm(addonSelected.value_tc !== true)
									}
									name="value_tc"
									inputProps={{ 'aria-label': 'text-base' }}
								/>
							}
						/>
					</div>
					<div className="w-full flex justify-end mt-0 p-10">
						<button
							onClick={(e) => handleSaveAnswer()}
							className="primary-button min-w-32"
							disabled={!complete || !addonSelected.value_tc}
						>
							Guardar
						</button>
					</div>
				</div>
			</div>
			{showDialog && (
				<TermsTituAddonDialog
					open={showDialog}
					handleClose={handleCloseTerms}
					termsCondition={props.addon.terms_conditions}
				/>
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	titular: state.affiliation.get('titular')
});

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthSurveyDialog);
