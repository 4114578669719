import React, { useState } from 'react';
import { SelectCurrency } from 'components/commons';
// import TermsConditionsDialogAddOn from '../terms/TermsConditionsDialogAddOn';
import { formatFloat } from '../utils/formatFloat';

const BeneficiarySummary = ({ beneficiaries, handleAssignAddon, isoCode }) => {
	const [showTerms, setShowTerms] = useState(false);
	const [id, setId] = useState(null);
	const [addonId, setAddonId] = useState(null);

	const handleClose = () => {
		setShowTerms(false);
		setId(null);
	};

	const handleSelect = (_id, addon_id) => {
		setId(_id);
		setAddonId(addon_id);
		setShowTerms(true);
	};

	const handleSubmit = async (flag) => {
		if (flag) {
			await handleAssignAddon(id, addonId);
			handleClose();
		} else {
			handleClose();
		}
	};

	return (
		<div>
			{beneficiaries.map((b, index) => (
				<div
					key={index}
					className={`w-full flex flex-col md:flex-row justify-between items-center text-gray-800 bg-white border py-3 px-4 ${
						index > 0 ? 'border-t-0' : ''
					}`}
				>
					<span className="font-bold text-sm">Beneficiario nº {index + 1}</span>
					<span className="flex-1 pl-0 md:pl-5 py-2 md:py-0 capitalize">{`${b.first_name} ${b.last_name}`}</span>
					<span className="flex-1 text-center pb-2 md:pb-0">
						{/* {b.addon ? (
              <span className="bg-purple-200 rounded-full py-1 px-2 text-xs text-purple-500">Con servicio funerario</span>
            ) : (
              <span
                className="py-1 px-2 text-small tracking-widest text-pink-500 font-bold uppercase cursor-pointer"
                onClick={() => handleSelect(b._id)}>Contratarle servicio funerario ></span>
            )} */}
					</span>
					<span className="flex items-center">
						<span className="flex-1 text-right">
							{isoCode && isoCode.toUpperCase()}{' '}
							{b?.currency[isoCode] && formatFloat(b?.currency[isoCode])}
						</span>
						<span className="ml-6">
							<SelectCurrency hideFlag={true} />
						</span>
					</span>
				</div>
			))}
			{/* <TermsConditionsDialogAddOn
        open={showTerms}
        handleClose={handleSubmit}
      /> */}
		</div>
	);
};

export default BeneficiarySummary;
