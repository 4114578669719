import React, { Component } from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DOMPurify from 'isomorphic-dompurify';

const sanitizer = DOMPurify.sanitize;

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

class TermsConditionDialogAddOn extends Component {
	render() {
		const { fullScreen, handleClose, addon } = this.props;
		return (
			<Dialog
				open={this.props.open}
				maxWidth="md"
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
				fullScreen={fullScreen}
			>
				<DialogTitle className="text-gray-900 font-bold">
					<img
						src="/static/images/Asistensi_logotipo.svg"
						alt="asistensi_logotipo"
						width="160px"
					/>
				</DialogTitle>
				<DialogContent>
					{
						<div
							class="max-w-5xl mx-auto w-full"
							dangerouslySetInnerHTML={{
								__html: sanitizer(addon?.terms_conditions)
							}}
						></div>
					}
					{
						<div className="flex justify-evenly my-10">
							<button
								className="secondary-button-nav mr-2"
								onClick={() => handleClose(false)}
							>
								No acepto
							</button>
							<button
								className="white-button-nav"
								onClick={() => handleClose(true)}
							>
								Acepto
							</button>
						</div>
					}
				</DialogContent>
			</Dialog>
		);
	}
}

export default withMobileDialog({ breakpoint: 'xs' })(
	TermsConditionDialogAddOn
);
