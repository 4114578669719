import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import CountryList from '../utils/CountryList';
import {
	TextField,
	FormControlLabel,
	MenuItem,
	Dialog,
	DialogContent,
	DialogActions,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	InputAdornment,
	Radio
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactTelInput from 'react-telephone-input';
import { TermsConditionsDialog } from 'components/terms';
import { isArray } from 'util';
import Validations from '../utils/Validations';
import logoStripe from '../../assets/images/logos/powered_by_stripe.svg';
import Flags from '../../assets/images/flags.png';
import FileWithIconInput from 'components/commons/FileWithIconInput';
import {
	SpinnerV,
	SnackBar,
	ConfirmDialog,
	DialogConfirmPhone
} from '../widgets';
import AddonsCardTitular from '../addons/AddonsCardTitular';
import BeneficiarySummary from '../affiliation/BeneficiarySummary';
import { CardForm } from 'components/forms';
import { formatFloat } from '../utils/formatFloat';
import TermsDIPDialog from '../terms/TermsDIPDialog';
import DownloadFileButton from '../commons/DownloadFileButton';

function InfoDialog(props) {
	return (
		<Dialog open={props.open}>
			<DialogContent>
				<div className="font-normal text-base leading-relaxed w-full">
					Si escoges esta forma de pago, al finalizar el proceso uno de nuestros
					ejecutivos de{' '}
					<span className="text-pink-500 font-bold"> asistensi</span> se pondrá
					en contacto con Ud. con la finalidad de coordinar una cita para la
					entrega del pago en efectivo.
				</div>
			</DialogContent>
			<DialogActions>
				<button onClick={props.handleClose} className="white-button-nav">
					Cerrar
				</button>
			</DialogActions>
		</Dialog>
	);
}

const paymentMethodName = {
	Z: 'de Zelle',
	P: 'de PayPal',
	T: 'del Banco'
};

class PaymentForm extends Component {
	state = {
		openDialog: false,
		methods: [],
		dialog: false,
		openAlert: false,
		messageAlert: '',
		changeCoupon: false,
		showConfirm: false,
		showSpinner: false,
		addonsExtra: [],
		activePaymentsForms: [],
		showConfirmACUNSA: false,
		verifiedNumber: '',
		DIPDialog: false,
		openPayment: false,
	};

	handleClose = () => this.setState({ openDialog: false });

	closeDialog = (param) => {
		let { payment, titular } = this.props;
		const isAcunsa = titular.country === 'España';

		if (isAcunsa) {
			this.setState({ dialog: false }, () => {
				if (param === true) this.verifyAcunsaCode(1, false);
				else {
					this.setState({ dialog: false }, () => {
						payment.acceptTerms = false;
						Validations.validatePaymentForm(
							payment,
							this.props.saveState,
							'acceptTerms',
							false
						);
						this.props.calculations();
						this.props.saveState({ payment });
					});
				}
			});
		} else {
			this.setState({ dialog: false });
			this.handleCheckbox('acceptTerms', param);
		}
	};

	closeDIPDialog = (param) => {
		this.setState({ DIPDialog: false });
		this.handleCheckbox('DIP', param);
	};

	verifyAcunsaCode = async (step, verification, callback) => {
		let { actions, titular, payment } = this.props;
		let { verifiedNumber } = this.state;
		if (
			this.props.paymentHeadlineForm &&
			(!/^([0-9()+ -]{10,})$/.test(titular.phone_one) || !titular.phone_one)
		) {
			return this.setState({
				messageAlert: 'El titular debe poseer un número de teléfono válido.',
				openAlert: true
			});
		}

		await this.setState({ showSpinner: true });

		let data = {
			_id: titular._id,
			verification,
			phone_one: this.props.paymentHeadlineForm ? titular.phone_one : '',
			verifiedNumber: verifiedNumber
		};

		let response = await actions.sendAcunsaCode(data);

		this.setState({ ...response }, async () => {
			if (response.success && step === 1) {
				await this.setState({ showConfirmACUNSA: true });
				if (typeof callback === 'function') callback();
			} else if (step === 2 && response.success === true) {
				payment.acceptTerms = true;
				Validations.validatePaymentForm(
					payment,
					this.props.saveState,
					'acceptTerms',
					true
				);
				this.setState({ showConfirmACUNSA: false, verifiedNumber: '' });
				this.props.calculations();
				this.props.saveState({ payment });
			}
		});
	};

	handleVerifyCodeInput = (e) => {
		let value = e.target.value;
		var regex = /[0-9]|\./;
		if (!regex.test(value)) {
			if (!value) this.setState({ verifiedNumber: value });
			else return;
		} else {
			if (value.length > 6) return;
			this.setState({ verifiedNumber: value });
		}
	};

	handleCoupon = async (e) => {
		e.preventDefault();
		const { coupon } = this.props;
		const response = await this.props.validateCoupon({ coupon });
		this.setState({ ...response });
	};

	handleInputCoupon = (event) => {
		const { value } = event.target;
		this.props.saveCoupon({ percent_off: 0, coupon: value });
		this.props.calculations();
	};

	handleChangeCoupon = () => {
		this.setState({ changeCoupon: true, showConfirm: false });
		this.props.saveCoupon({ percent_off: 0, coupon: '' });
	};

	handleInput = (event) => {
		let { name, value } = event.target;
		let { payment, titular } = this.props;
		const isAcunsa = titular.country === 'España';

		if (name === 'country') {
			// payment.acceptTerms = false;
			// payment.DIP = false;
			// if(this.props.paymentHeadlineForm && payment.use_titular_data){
			//   titular.country = value
			//   this.props.saveState("titular", titular)
			// }
		} else if (
			name === 'zip_zone' &&
			value.length > 5 &&
			isAcunsa &&
			payment.use_titular_data
		) {
			return;
		}
		payment[name] = value;
		this.props.saveState({ payment });
		Validations.validatePaymentForm(payment, this.props.saveState, name, value);
		this.props.calculations();
		this.setState({ openDialog: false });
	};

	handleCloseAlert = () => this.setState({ openAlert: false });

	handleInputSelect = (event) => {
		let { name, value } = event.target;
		let payment = this.props.payment;
		payment[name] = value;
		payment.payment_voucher = null;
		payment.reference_number = '';
		this.props.saveState({ payment });
		Validations.validatePaymentForm(payment, this.props.saveState, name, value);
		if (value === 'E') this.setState({ openDialog: true });
		if (value === 'S') this.setState({ openPayment: true });
		else this.setState({ openDialog: false });
		this.props.calculations();
	};

	async componentDidMount() {
		let { payment, isoCode } = this.props;
		const default_payment = this.props.paymentMethods.find(
			(pm) => pm.default === true
		);

		if (default_payment) {
			payment.payment_form = default_payment;
			payment.payment_interval = default_payment.recurrent_interval;
			this.props.calculateTotalPayment({
				isoCode,
				total: default_payment?.total,
				total_currency: default_payment?.currency
			});
		} else {
			const default_method =
				isArray(this.props.paymentMethods) &&
				this.props.paymentMethods.length > 0
					? this.props.paymentMethods[0]
					: {};
			payment.payment_form = default_method;
			payment.payment_interval = default_method.recurrent_interval;
			this.props.calculateTotalPayment({
				isoCode,
				total: default_method?.total,
				total_currency: default_method?.currency
			});
		}
		payment.payment_methods = this.props.paymentMethods;
		this.props.saveState({ payment });

		/*Addons Titular*/
		const { titular } = this.props;
		if (titular.sex && titular.birth_date && titular.country_code) {
			this.setState({ showSpinner: true });
			const result = await this.props.actions.findAddonByTitular({
				sex: titular.sex,
				birth_date: titular.birth_date,
				country_code: titular.country_code
			});
			if (result.success) {
				this.setState({ addonsExtra: result.addons });
			} else if (!result.success) {
				this.setState({ ...result });
			}
			this.setState({ showSpinner: false });
		}

		if (this.props.active_payment_forms) {
			const forms = this.props.payment?.payment_form?.paymentForms.filter((p) =>
				this.props.active_payment_forms.includes(p.value)
			);
			this.setState({ activePaymentsForms: forms });
		}
	}

	componentDidUpdate(prevProps) {
		let { payment } = this.props;
		if (this.props.active_payment_forms !== prevProps.active_payment_forms) {
			const forms = this.props.payment?.payment_form?.paymentForms.filter((p) =>
				this.props.active_payment_forms.includes(p.value)
			);
			this.setState({ activePaymentsForms: forms });
			payment.payment_method = 'default';
			this.props.saveState({ payment });
		}
	}

	handleInputSelectPaymentForm = async (event) => {
		let { value } = event.target;
		let { payment, isoCode } = this.props;
		const form = payment.payment_methods.find(
			(m) => m.recurrent_interval === value
		);

		payment.payment_form = form;
		payment.payment_interval = value;
		payment.payment_method = '';

		Validations.validatePaymentForm(
			payment,
			this.props.saveState,
			'payment_interval',
			value
		);
		this.props.calculateTotalPayment({
			isoCode,
			total: form?.total,
			total_currency: form?.currency
		});
		this.props.saveState({ payment });
		this.props.calculations();
	};

	handleInputFile = (event) => {
		let { name, files } = event.target;
		let payment = this.props.payment;
		payment[name] = files[0];
		this.props.saveState({ payment });
		Validations.validatePaymentForm(
			payment,
			this.props.saveState,
			name,
			files[0]
		);
		this.props.calculations();
		this.setState({ openDialog: false });
	};

	handlePhone = (name, value) => {
		let payment = this.props.payment;
		payment[name] = value;
		this.props.saveState({ payment });
		Validations.validatePaymentForm(payment, this.props.saveState, name, value);
		this.props.calculations();
	};

	handleCheckbox = (e, param) => {
		const { payment, titular } = this.props;
		const isAcunsa = titular.country === 'España';

		if (e === 'acceptTerms') {
			if (isAcunsa) {
				if (payment.acceptTerms === false) {
					payment.acceptTerms = false;
				} else {
					payment.acceptTerms = param;
					Validations.validatePaymentForm(
						payment,
						this.props.saveState,
						'acceptTerms',
						param
					);
				}
			} else {
				payment.acceptTerms = param;
				Validations.validatePaymentForm(
					payment,
					this.props.saveState,
					'acceptTerms',
					param
				);
			}
		} else if (e === 'DIP') {
			payment.DIP = param;
			Validations.validatePaymentForm(
				payment,
				this.props.saveState,
				'DIP',
				param
			);
		} else {
			const { name } = e.target;
			payment[name] = param;
			Validations.validatePaymentForm(
				payment,
				this.props.saveState,
				name,
				param
			);
		}
		this.props.calculations();
		this.props.saveState({ payment });
		this.setState({ openDialog: false });
	};

	calculateRechargeAmount = (recharge) => {
		const { totalPayment, isoCode, total_currency } = this.props;
		if (!recharge || !totalPayment || !total_currency) return '';
		return (
			<span className="text-xs text-pink-500">
				+ {isoCode} {formatFloat((recharge * total_currency) / 100)}
			</span>
		);
	};

	handleSelectAddon = async (flag, value, idx) => {
		this.setState({ showSpinner: true });
		const { titular } = this.props;
		let result = {};
		if (titular && !titular.addons_titular) titular.addons_titular = [];
		const index = titular.addons_titular.findIndex(
			(addon) => addon.id_addon === idx
		);

		if (flag || (!flag && index >= 0)) {
			result = await this.props.actions.saveAddonTitular({
				...value,
				value: flag,
				id_addon: idx
			});
		}

		if (result.success) {
			titular.addons_titular = result.addons_titular;
			let { payment, isoCode } = this.props;
			const form = result.payment_methods?.find(
				(m) => m.recurrent_interval === payment.payment_interval
			);
			payment.payment_form = form;
			payment.payment_methods = result.payment_methods;
			this.props.calculateTotalPayment({
				isoCode,
				total: form?.total,
				total_currency: form?.currency
			});
			this.props.saveState({ payment });
		} else if (!result.success) {
			this.setState({ ...result });
		}

		this.setState({ showSpinner: false });
		this.props.actions.handleStateAffiliation('titular', titular);
	};

	payment_inputs(payment) {
		let { titular } = this.props;

		return (
			<Accordion
				className="border border-gray-300 shadow-none rounded-none m-0 mt-m-1 delete"
				expanded={this.props.expanded === 'payment-person'}
				onChange={this.props.handleChange('payment-person')}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<div className="flex-center w-full">
						<span className="text-base font-bold text-gray-800 leading-relaxed">
							Datos del pagador
						</span>
					</div>
				</AccordionSummary>
				<AccordionDetails className="bg-gray-100 border-t">
					<div className="flex flex-wrap">
						<FormControlLabel
							className="w-full m-0"
							label={
								<span
									className={`text-gray-800 text-base ${
										payment.use_titular_data === true ? 'font-bold' : 'normal'
									}`}
								>
									Usar la información de mi cuenta
								</span>
							}
							control={
								<Checkbox
									name="use_titular_data"
									required={true}
									className={
										payment.use_titular_data === true
											? 'checkBoxActive'
											: 'checkBox'
									}
									onClick={() => {
										if (payment.use_titular_data) {
											payment.country = 'default';
											payment.state = '';
											payment.city = '';
										} else {
											payment.country = titular.country;
											payment.state = titular.city;
											payment.city = titular.city;
											payment.dni = titular.dni;
											payment.phone_one = titular.phone_one;
										}
										this.props.handleUseTitularData(
											payment.use_titular_data === true ? false : true
										);
										this.props.saveState('payment', payment);
									}}
									checked={payment.use_titular_data}
								/>
							}
						/>
						<div className="w-full flex flex-wrap payment-form">
							{payment.use_titular_data === false && (
								<TextField
									name={'first_name'}
									label="Nombres"
									placeholder="Nombres"
									type="text"
									helperText={payment.formErrors.first_name}
									variant="outlined"
									error={true}
									className="wInputThree"
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root: !payment.first_name
												? 'cssLabel'
												: payment.formErrors.first_name
												? 'cssLabelError'
												: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline: !payment.first_name
												? 'cssInput'
												: payment.formErrors.first_name
												? 'cssInputError'
												: 'cssInputSuccess'
										}
									}}
									margin="normal"
									value={payment.first_name}
									required={true}
									onChange={(event) => this.handleInput(event)}
								/>
							)}
							{payment.use_titular_data === false && (
								<TextField
									name={'last_name'}
									label="Apellidos"
									placeholder="Apellidos"
									type="text"
									helperText={payment.formErrors.last_name}
									variant="outlined"
									error={true}
									className="wInputThree"
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root: !payment.last_name
												? 'cssLabel'
												: payment.formErrors.last_name
												? 'cssLabelError'
												: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline: !payment.last_name
												? 'cssInput'
												: payment.formErrors.last_name
												? 'cssInputError'
												: 'cssInputSuccess'
										}
									}}
									margin="normal"
									value={payment.last_name}
									required={true}
									onChange={this.handleInput}
								/>
							)}

							{payment.use_titular_data === false && (
								<TextField
									name={'dni'}
									label="Número de Documento"
									placeholder="Número de Documento"
									helperText={payment.formErrors.dni}
									variant="outlined"
									error={true}
									className="wInputThree"
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root: !payment.dni
												? 'cssLabel'
												: payment.formErrors.dni
												? 'cssLabelError'
												: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline: !payment.dni
												? 'cssInput'
												: payment.formErrors.dni
												? 'cssInputError'
												: 'cssInputSuccess'
										}
									}}
									margin="normal"
									value={payment.dni}
									required={true}
									onChange={this.handleInput}
								/>
							)}
							{payment.use_titular_data === false && (
								<TextField
									name={'email'}
									label="Correo electrónico"
									placeholder="Correo electrónico"
									type="email"
									helperText={payment.formErrors.email}
									variant="outlined"
									error={true}
									className="wInputThree"
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root: !payment.email
												? 'cssLabel'
												: payment.formErrors.email
												? 'cssLabelError'
												: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline: !payment.email
												? 'cssInput'
												: payment.formErrors.email
												? 'cssInputError'
												: 'cssInputSuccess'
										}
									}}
									margin="normal"
									value={payment.email}
									required={true}
									onChange={this.handleInput}
								/>
							)}
							{payment.use_titular_data === false && (
								<ReactTelInput
									required={true}
									onChange={(telNumber) =>
										this.handlePhone('phone_one', telNumber)
									}
									defaultCountry="do"
									flagsImagePath={Flags}
									className={
										!payment.phone_one
											? 'telInput'
											: payment.formErrors.phone_one
											? 'telInputError'
											: 'telInput'
									}
								/>
							)}
							<TextField
								name={'zip_zone'}
								label="Zona Postal"
								placeholder="Zona Postal"
								type="number"
								helperText={payment.formErrors.zip_zone}
								variant="outlined"
								error={true}
								className={`${
									payment.use_titular_data ? 'wInputFull' : 'wInputThree'
								}`}
								FormHelperTextProps={{
									classes: { root: 'cssHelperTextError' }
								}}
								InputLabelProps={{
									classes: {
										root: !payment.zip_zone
											? 'cssLabel'
											: payment.formErrors.zip_zone
											? 'cssLabelError'
											: 'cssLabelSuccess'
									},
									shrink: true
								}}
								InputProps={{
									classes: {
										notchedOutline: !payment.zip_zone
											? 'cssInput'
											: payment.formErrors.zip_zone
											? 'cssInputError'
											: 'cssInputSuccess'
									}
								}}
								margin="normal"
								value={payment.zip_zone}
								required={true}
								onChange={this.handleInput}
							/>

							{!payment.use_titular_data && (
								<>
									<TextField
										select
										name={'country'}
										label="País"
										helperText={payment.formErrors.country}
										variant="outlined"
										error={true}
										className={`wInputThree ${
											payment.country === 'default' && 'default'
										}`}
										FormHelperTextProps={{
											classes: { root: 'cssHelperTextError' }
										}}
										InputLabelProps={{
											classes: {
												root: !payment.country
													? 'cssLabel'
													: payment.formErrors.country
													? 'cssLabelError'
													: 'cssLabelSuccess'
											},
											shrink: true
										}}
										InputProps={{
											classes: {
												notchedOutline: !payment.country
													? 'cssInput'
													: payment.formErrors.country
													? 'cssInputError'
													: 'cssInputSuccess'
											}
										}}
										SelectProps={{
											IconComponent: KeyboardArrowDownIcon
										}}
										margin="normal"
										value={payment.country}
										required={true}
										onChange={this.handleInput}
									>
										<MenuItem value="default" hidden disabled>
											Selecciona el país
										</MenuItem>
										{CountryList.map((country, i) => (
											<MenuItem key={i} value={country.name}>
												{country.name}
											</MenuItem>
										))}
									</TextField>

									<TextField
										name={'state'}
										label={`${
											payment?.country === 'República Dominicana'
												? 'Provincia'
												: 'Estado'
										}`}
										placeholder={`${
											payment?.country === 'República Dominicana'
												? 'Provincia'
												: 'Estado'
										}`}
										type="text"
										helperText={payment.formErrors.state}
										variant="outlined"
										error={true}
										className="wInputThree"
										FormHelperTextProps={{
											classes: { root: 'cssHelperTextError' }
										}}
										InputLabelProps={{
											classes: {
												root: !payment.state
													? 'cssLabel'
													: payment.formErrors.state
													? 'cssLabelError'
													: 'cssLabelSuccess'
											},
											shrink: true
										}}
										InputProps={{
											classes: {
												notchedOutline: !payment.state
													? 'cssInput'
													: payment.formErrors.state
													? 'cssInputError'
													: 'cssInputSuccess'
											}
										}}
										margin="normal"
										value={payment.state}
										required={true}
										onChange={this.handleInput}
									/>

									<TextField
										name={'city'}
										label="Ciudad"
										placeholder="Ciudad"
										type="text"
										helperText={payment.formErrors.city}
										variant="outlined"
										error={true}
										className="wInputThree"
										FormHelperTextProps={{
											classes: { root: 'cssHelperTextError' }
										}}
										InputLabelProps={{
											classes: {
												root: !payment.city
													? 'cssLabel'
													: payment.formErrors.city
													? 'cssLabelError'
													: 'cssLabelSuccess'
											},
											shrink: true
										}}
										InputProps={{
											classes: {
												notchedOutline: !payment.city
													? 'cssInput'
													: payment.formErrors.city
													? 'cssInputError'
													: 'cssInputSuccess'
											}
										}}
										margin="normal"
										value={payment.city}
										required={true}
										onChange={this.handleInput}
									/>
								</>
							)}
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
		);
	}

	interval(param) {
		if (param === 'month') {
			return this.props.renovationPath ? 'Mensual' : 'Inicial + 10';
		} else if (param === 'year') {
			return 'Anual';
		} else if (param === 'week') {
			return 'Semanal';
		} else if (param === 'day') {
			return 'Diario';
		} else if (param === 'bimonthly') {
			return 'Bimestral';
		} else if (param === 'quarterly') {
			return 'Trimestral';
		} else if (param === 'semiannual') {
			return 'Semestral';
		} else {
			return '';
		}
	}

	handleAssignAddon = async (id, addonID) => {
		const {
			payment,
			actions,
			calculateTotalPayment,
			isoCode,
			saveState,
			titular,
			beneficiaries,
			is_renewal,
			paymentIDForRenew
		} = this.props;
		let result = null;
		if (id && addonID) {
			this.setState({ showSpinner: true });
			if (is_renewal)
				result = await actions.assignAddonToBeneficiaryRenew({
					id,
					addonID,
					paymentID: paymentIDForRenew
				});
			else result = await actions.assignAddonToBeneficiary({ id, addonID });

			this.setState({ ...result }, async () => {
				if (result.success) {
					const form = result.payment_methods.find(
						(m) => m.recurrent_interval === payment.payment_interval
					);
					payment.payment_form = form;
					payment.payment_methods = result.payment_methods;
					calculateTotalPayment({
						isoCode,
						total: form?.total,
						total_currency: form?.currency
					});
					await this.props.actions.handleStateAffiliation('payment', payment);
					// await saveState({payment});

					if (result.customer?.kinship === 'TITULAR') {
						await this.props.actions.handleStateAffiliation('titular', {
							...titular,
							...result.customer
						});
						// await saveState('titular', {titular: {...titular, ...result.customer}});
					} else {
						const idx = beneficiaries.findIndex(
							(b) => b._id === result.customer._id
						);
						if (idx > -1) {
							beneficiaries[idx] = {
								...beneficiaries[idx],
								...result.customer
							};
							await this.props.actions.handleStateAffiliation(
								'beneficiaries',
								beneficiaries
							);
							// await saveState({beneficiaries});
						}
					}
				}
			});
		}
	};
	
	render() {
		const { payment, autoPayRenew, titular, isoCode, symbol, total_currency, renovationPath, percent_off } =
			this.props;
		const isAcunsa = titular.country === 'España';

		const calculateTotalAnmount = (percent_off) => {
			if(percent_off) {
				return (payment.payment_interval === 'month' && !renovationPath )? (
					formatFloat(2 * this.props.calculateTotal(total_currency[isoCode], percent_off))
				) : (
					formatFloat(this.props.calculateTotal(total_currency[isoCode], percent_off))
				)
			}
			return (payment.payment_interval === 'month' && !renovationPath )? (
					formatFloat(2 * this.props.calculateTotal(total_currency[isoCode]))
				) : (
					formatFloat(this.props.calculateTotal(total_currency[isoCode]))
				)
		}
		return (
			<div className="w-full">
				<style global jsx>
					{`
						.mt-m-1 {
							margin-top: -1px !important;
						}
						.delete::before {
							height: 0px;
						}
					`}
				</style>
				{this.state.showSpinner === true && <SpinnerV />}
				<div className="w-full">
					{!autoPayRenew && this.payment_inputs(payment)}
					<Accordion
						className="border border-gray-300 shadow-none rounded-none m-0 mt-m-1 delete"
						expanded={this.props.expanded === 'payment-info'}
						onChange={this.props.handleChange('payment-info')}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<div className="flex items-center w-full justify-between">
								<span className="text-base font-bold text-gray-800 leading-relaxed">
									Selecciona tu forma de pago
								</span>
							</div>
						</AccordionSummary>
						<AccordionDetails className="bg-gray-100 border-t">
							<div className="d-flex flex-column max-w-[800px] w-full">
								<div className="md:p-10 sm:p-6">
									<p>Tu frecuencia de pago es:</p>
									<div className="flex justify-center md:justify-start flex-wrap gap-4">
										<div>
											<div
											style={{
												maxWidth: '500px', width: '100%',
												color: '#2B0549', backgroundColor: '#E1E4F4', padding: 15,
												fontSize: 20, display: 'flex', justifyContent: 'space-around', alignItems: 'center',
												borderRadius: 4
											}}
											>
											<Radio checked className="text-purple-500"/>
											<div className="flex items-center justify-start">
												<p style={{width: 118}}>
													{
														payment?.payment_interval === 'year' ? '1 Pago anual' :
														payment?.payment_interval === 'semiannual' ? '2 Pagos semestrales' :
														payment?.payment_interval === 'quarterly' ? '4 Pagos trimestrales' :
														renovationPath ? '12 Pagos mensuales' : '1 Pago inicial'
													}
												</p>
												<p className="text-pink-500" style={{marginRight: 40, fontWeight: 'bold', fontSize: 25}}>
													{(payment?.payment_interval === 'month' && !renovationPath) ? '+' : null}
												</p>
												{
													(payment?.payment_interval === 'month' && !renovationPath) &&
														<p style={{width: 118}}>10 Pagos mensuales</p>
												}
											</div>
											
											<p style={{fontWeight: 'bold'}}>{`${formatFloat(payment?.total_currency[this.props.isoCode])} ${this.props.isoCode?.toUpperCase()}`}</p>
											</div>
											<div className="text-right mt-2 mr-2" >
												{ payment?.payment_interval === 'year' ? (
													<div style={{color: '#FF2E65'}}>
														<p >Este es el mejor precio en asistensi</p>
													</div>
												) : (
													<div style={{color: '#FF2E65', paddingLeft: 50}}>
														<p >Ahorra mas con una frecuencia anual</p>
													</div>
												)
												}
											</div>
										</div>
										<button onClick={() => this.setState({showSelectInterval: true})} className="text-purple-500 font-bold text-xl mb-8">Cambiar frecuencia</button>
									</div>
									
								</div>
								<div className="w-full flex flex-wrap">
								{this.state.showSelectInterval &&
									<TextField
									select
									label="Frecuencia de pago"
									name="payment_interval"
									value={payment.payment_interval}
									onChange={(e) => this.handleInputSelectPaymentForm(e)}
									variant="outlined"
									error={true}
									className={`wInputThree ${
										payment.payment_interval === 'default' && 'default'
									}`}
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root: !payment.payment_interval
												? 'cssLabelError'
												: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline: !payment.payment_interval
												? 'cssInputError'
												: 'cssInputSuccess'
										}
									}}
									SelectProps={{
										IconComponent: KeyboardArrowDownIcon
									}}
									helperText={payment.formErrors.payment_interval}
									margin="normal"
								>
									<MenuItem value="default" hidden disabled>
										Selecciona la frecuencia
									</MenuItem>
									{this.props.paymentMethods.map((form, i) => (
										<MenuItem key={i} value={form.recurrent_interval}>
											{this.interval(form.recurrent_interval)}
										</MenuItem>
									))}
									</TextField>
								}
								<TextField
									select
									label="Forma de pago"
									name="payment_method"
									value={payment.payment_method}
									onChange={(e) => this.handleInputSelect(e)}
									variant="outlined"
									error={true}
									className={`field-responsive wInputThree m-t25 ${
										payment.payment_method === 'default' && 'default'
									}`}
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root: !payment.payment_method
												? 'cssLabel'
												: payment.formErrors.payment_method
												? 'cssLabelError'
												: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline: !payment.payment_method
												? 'cssInput'
												: payment.formErrors.payment_method
												? 'cssInputError'
												: 'cssInputSuccess'
										}
									}}
									SelectProps={{
										IconComponent: KeyboardArrowDownIcon
									}}
									helperText={payment.formErrors.payment_method}
									margin="normal"
								>
									<MenuItem value="default" hidden disabled>
										Selecciona la forma de pago
									</MenuItem>
									{this.state.activePaymentsForms &&
									this.state.activePaymentsForms.length > 0 ? (
										this.state.activePaymentsForms.map((method, i) => (
											<MenuItem key={i} value={method.value}>
												{method.name}&nbsp;
												{this.calculateRechargeAmount(method.recharge)}
											</MenuItem>
										))
									) : (
										<MenuItem value="empty" hidden disabled>
											Debe seleccionar su frecuencia de pago
										</MenuItem>
									)}
								</TextField>
								{this.props.is_back_coupon !== true ||
								this.state.changeCoupon === true ? (
									<TextField
										name={'coupon'}
										label="Cupón de descuento"
										placeholder="Ingresa el cupón (opcional)"
										type="text"
										variant="outlined"
										className="field-responsive wInputThree m-t25"
										FormHelperTextProps={{
											classes: { root: 'cssHelperTextError' }
										}}
										InputLabelProps={{
											classes: {
												root: 'cssLabel'
											},
											shrink: true
										}}
										InputProps={{
											classes: {
												notchedOutline: 'cssInput'
											},
											endAdornment: (
												<InputAdornment position="end">
													<button
														className="button-right"
														aria-label="send coupon"
														onClick={(e) => this.handleCoupon(e)}
													>
														<SearchIcon />
													</button>
												</InputAdornment>
											)
										}}
										margin="normal"
										value={this.props.coupon || ''}
										onChange={(e) => this.handleInputCoupon(e)}
									/>
								) : (
									<button
										className="cursor-pointer font-bold text-pink-500 text-xs font-poppins uppercase tracking-extra mt-2 ml-2"
										onClick={() => this.setState({ showConfirm: true })}
									>
										Aplicar nuevo cupón
									</button>
								)}
								{this.props.free_trial === true &&
									payment.payment_method !== 'S' && (
										<div className="bg-pink-light p-20 text-pink-500 font-14 leading-relaxed mt-5 rounded w-full">
											Para disfrutar del primer mes gratis, deberás pagar
											utilizando una tarjeta de crédito/débito.
										</div>
									)}
								{this.props.free_trial === true &&
									payment.payment_method === 'S' &&
									(this.props.coupon === 'job-and-talent' ||
										this.props.coupon === 'caracas-press-club-10' ||
										this.props.coupon === 'empresas-polar-10') && (
										<p className="bg-purple-200 p-5 text-purple-500 font-14 leading-relaxed mt-5 rounded w-full">
											*Pagando con tarjeta de crédito/débito la afiliación tiene
											30 días completamente gratis. Y una vez transcurrido este
											tiempo, obtendrás el 10% de descuento sobre la anualidad.
											El monto a cancelar está reflejado a continuación.
										</p>
									)}
								{this.state.openDialog === true && (
									<InfoDialog
										open={this.state.openDialog}
										handleClose={this.handleClose}
									/>
								)}

								{/* Effective data */}
								{payment.payment_method === 'E' && (
									<div className="w-full mx-2 mt-5">
										<div className="text-gray-800 text-base leading-relaxed">
											¡Listo! Para completar el proceso sólo debes imprimir el
											comprobante que recibirás en tu correo electrónico y
											esperar la llamada de uno de nuestros ejecutivos de
											asistensi para hacer entrega del pago de{' '}
											{isoCode && isoCode.toUpperCase()}.
											{this.props.percent_off && this.props.percent_off > 0 ? (
												<>
													{this.props.free_trial === true ? (
														<span className="mx-1">
															{symbol}{' '}
															{calculateTotalAnmount()}
														</span>
													) : (
														<>
															<span className="line-through mr-1">
																{symbol}{' '}
																{calculateTotalAnmount()}
															</span>
															<span className="font-bold">
																{symbol}{' '}
																{calculateTotalAnmount(percent_off)}
															</span>
														</>
													)}
												</>
											) : (
												<>
													{symbol}{' '}
													{calculateTotalAnmount()}
												</>
											)}{' '}
											en efectivo.
										</div>
									</div>
								)}

								{/* Check data */}
								{payment.payment_method === 'C' && (
									<div className="w-full mx-2 mt-5">
										<p className="text-gray-800 text-base leading-relaxed">
											¡Listo! Para completar el proceso sólo debes imprimir el
											comprobante que recibirás en tu correo electrónico y
											dirigirte a la siguiente dirección para hacer entrega del
											cheque por el pago de {isoCode && isoCode.toUpperCase()}{' '}
											{this.props.percent_off && this.props.percent_off > 0 ? (
												<>
													{this.props.free_trial === true ? (
														<span>
															{formatFloat(
																this.props.calculateTotal(
																	total_currency[isoCode]
																)
															)}
														</span>
													) : (
														<>
															<span className="line-through mr-1">
																{formatFloat(
																	this.props.calculateTotal(
																		total_currency[isoCode]
																	)
																)}
															</span>
															<span className="font-bold">
																{formatFloat(
																	this.props.calculateTotal(
																		total_currency[isoCode],
																		this.props.percent_off
																	)
																)}
															</span>
														</>
													)}
												</>
											) : (
												<>
													{formatFloat(
														this.props.calculateTotal(total_currency[isoCode])
													)}
												</>
											)}
										</p>
										<div className="text-gray-700 text-sm">
											<span className="font-bold">A nombre de: </span> Vida
											Healthcare
										</div>
										{this.props.paymentAddress.map((address, i) => (
											<div className="flex flex-col m-t10" key={i}>
												<div className="flex-center text-sm text-gray-700">
													<span className="font-bold mr-1">Ciudad:</span>
													{address.city}
												</div>
												<span className="text-gray-800 font-bold text-sm">
													{address.address}
												</span>
											</div>
										))}
									</div>
								)}
								{(payment.payment_method === 'Z' ||
									payment.payment_method === 'T' ||
									payment.payment_method === 'P') && (
									<div className="w-full mx-2 mt-5">
										<div className="text-gray-800 text-base leading-relaxed">
											Datos de la cuenta
										</div>
										{
											// Zelle data
											payment.payment_method === 'Z' && (
												<div className="w-ful mt-2">
													<div className="text-gray-800 text-base leading-relaxed">
														<span className="font-bold mr-1">
															Nombre de la cuenta:
														</span>
														Asistensi Services USA LLC
													</div>
													<div className="text-gray-800 text-base leading-relaxed">
														<span className="font-bold mr-1">
															Correo de la cuenta a depositar:
														</span>
														chase@asistensi.com
													</div>
												</div>
											)
										}

										{
											// Transfer Data
											payment.payment_method === 'T' && (
												<div className="w-full mt-2">
													{isoCode === 'usd' && (
														<>
															<div className="text-gray-800 text-base leading-relaxed mt-5">
																<span className="font-bold mr-1 text-pink-500">
																	Estados Unidos:{' '}
																</span>
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">Banco: </span>{' '}
																JP Morgan Chase
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">
																	Nombre de la cuenta:{' '}
																</span>{' '}
																Asistensi Services USA LLC
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">
																	Número de cuenta:{' '}
																</span>{' '}
																673659055
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">
																	Número de enrutamiento ACH:{' '}
																</span>{' '}
																021000021
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">Swift: </span>{' '}
																CHASUS33
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">
																	Dirección:{' '}
																</span>{' '}
																4 New York Plaza, New York, NY, 10004, USA
															</div>
														</>
													)}

													{(isoCode === 'eur' || isoCode === 'EUR') && (
														<>
															<div className="text-gray-800 text-base leading-relaxed mt-5">
																<span className="font-bold mr-1 text-pink-500">
																	Transferencia en Euros:{' '}
																</span>
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">Banco: </span>{' '}
																Banco Santander S.A.
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">IBAN: </span>
																ES92 0049 1032 3129 10058026
															</div>
														</>
													)}

													{isoCode === 'dop' && (
														<>
															<div className="text-gray-800 text-base leading-relaxed mt-5">
																<span className="font-bold mr-1 text-pink-500">
																	República Dominicana:{' '}
																</span>
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">Banco: </span>{' '}
																Banco Popular
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">
																	Nombre de la cuenta:{' '}
																</span>{' '}
																Asistensi Sucursal Dominicana, SRL
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">
																	Número de cuenta:{' '}
																</span>{' '}
																821699600
															</div>
															<div className="text-gray-800 text-base leading-relaxed">
																<span className="font-bold mr-1">RNC: </span>{' '}
																132-09820-1
															</div>
														</>
													)}
												</div>
											)
										}

										{
											// PayPal Data
											payment.payment_method === 'P' && (
												<div className="w-full mt-2">
													<div className="text-gray-800 text-base leading-relaxed">
														<span className="font-bold mr-1">
															Nombre de la cuenta:{' '}
														</span>{' '}
														Asistensi Services Usa LLC
													</div>
													<div className="text-gray-800 text-base leading-relaxed">
														<span className="font-bold mr-1">Dirección: </span>{' '}
														paypal.me/asistensipagos
													</div>
													<div className="text-gray-800 text-base leading-relaxed">
														<span className="font-bold mr-1">Email: </span>{' '}
														pagos@asistensi.com
													</div>
												</div>
											)
										}

										<div className="bg-purple-200 rounded p-3 my-3">
											<span className="text-sm text-purple-500">
												Para validar más rápido y efectivamente tu pago,
												agradecemos que al momento de efectuar la transacción en
												la plataforma{' '}
												{paymentMethodName[payment.payment_method]} identifiques
												el concepto del pago con el NOMBRE, APELLIDO y NÚMERO DE
												IDENTIFICACIÓN del titular de la póliza.
											</span>
										</div>

										<div className="bg-pink-200 text-pink-500 text-sm leading-relaxed p-5 rounded my-4">
											<div className="text-xl font-bold">¡Recuerda!</div>
											<p>
												El conteo de los plazos de espera comienza a partir de
												la confirmación de los pagos realizados a través de
												Zelle, PayPal o Transferencia. ¡No te preocupes!
												Recibirás una notificación cuando confirmemos tu pago.
											</p>
										</div>

										<div className="flex flex-wrap pt-2">
											<TextField
												name={'reference_number'}
												label="Número de Comprobante"
												placeholder="Número de Comprobante"
												type="text"
												helperText={payment.formErrors.reference_number}
												variant="outlined"
												error={true}
												className="wInputTwo"
												FormHelperTextProps={{
													classes: { root: 'cssHelperTextError' }
												}}
												InputLabelProps={{
													classes: {
														root: !payment.reference_number
															? 'cssLabel'
															: payment.formErrors.reference_number
															? 'cssLabelError'
															: 'cssLabelSuccess'
													},
													shrink: true
												}}
												InputProps={{
													classes: {
														notchedOutline: !payment.reference_number
															? 'cssInput'
															: payment.formErrors.reference_number
															? 'cssInputError'
															: 'cssInputSuccess'
													}
												}}
												margin="normal"
												value={payment.reference_number}
												required={true}
												onChange={(event) => this.handleInput(event)}
											/>

											<FileWithIconInput
												name="payment_voucher"
												label="Comprobante de pago"
												required={true}
												value={payment.payment_voucher}
												placeholder="Seleccione el comprobante de pago"
												addClass="wInputTwo"
												changeClass={false}
												helperText={payment.formErrors.payment_voucher}
												onChange={(e) => this.handleInputFile(e)}
											/>
										</div>
									</div>
								)}

								{(payment.payment_method === 'S' ||
									payment.payment_method === 'B') && (
									<div className="w-full mx-2 mt-5">
										<span className="text-gray-800 text-base leading-relaxed">
											Ingrese los datos de la tarjeta de crédito
										</span>
										{
											// Stripe data step
											payment.payment_method === 'S' && (
												<div className="w-full mt-5">
													<CardForm
														name={`${payment?.first_name} ${payment?.last_name}`}
														handleSave={
															this.props.actions.handleStateAffiliation
														}
														payment={payment}
													/>
													<div className="w-full justify-end items-center">
														<img
															src={logoStripe}
															alt="logo stripe"
															className="logo-stripe mt-2"
														/>
													</div>
												</div>
											)
										}
									</div>
								)}

								{this.props.paymentResponse && (
									<div className="text-pink-500 font-bold text-sm w-full text-center mt-4">
										{this.props.paymentResponse}
									</div>
								)}
							</div>
							</div>
						</AccordionDetails>
					</Accordion>

					<Accordion
						className="border border-gray-300 shadow-none rounded-none m-0 mt-m-1 delete"
						expanded={this.props.expanded === 'payment-resum'}
						onChange={this.props.handleChange('payment-resum')}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<div className="flex items-center w-full justify-between">
								<span className="text-base font-bold text-gray-800 leading-relaxed">
									Resumen
								</span>
							</div>
						</AccordionSummary>
						<AccordionDetails className="bg-gray-100 border-t w-full">
							{
								<div className="w-full">
									{this.state.addonsExtra.length > 0 && (
										<AddonsCardTitular
											isoCode={isoCode}
											titular={this.props.titular}
											addonsList={this.state.addonsExtra}
											handleSelectAddon={this.handleSelectAddon}
											interval={payment.payment_interval}
										/>
									)}
									<BeneficiarySummary
										isoCode={isoCode}
										beneficiaries={
											payment.payment_form?.beneficiarie_resume ?? []
										}
										handleAssignAddon={this.handleAssignAddon}
									/>
								</div>
							}
						</AccordionDetails>
					</Accordion>

					<div
						className="flex w-full mt-5 cursor-pointer"
						onClick={() => {
							this.setState({ dialog: true });
						}}
					>
						<FormControlLabel
							className="m-0 w-full"
							label={
								<span
									className={`text-gray-800 text-base ${
										payment.acceptTerms === true ? 'font-bold' : 'font-normal'
									}`}
								>
									{isAcunsa ? 'Acepto las' : 'Acepto los'}
									<span className="mx-1 font-bold">
										{isAcunsa
											? 'Condiciones Generales de la Póliza'
											: 'Términos y Condiciones de uso'}
									</span>
								</span>
							}
							control={
								<Checkbox
									name="acceptTerms"
									required={true}
									className={
										payment.acceptTerms === true ? 'checkBoxActive' : 'checkBox'
									}
									onClick={(e) =>
										this.handleCheckbox(
											'acceptTerms',
											payment.acceptTerms === true ? false : true
										)
									}
									checked={payment.acceptTerms}
								/>
							}
						/>
						{isAcunsa && (
							<DownloadFileButton
								file="acunsa_policy_policies_do"
								fileName="Políticas de la póliza"
							/>
						)}
					</div>

					{isAcunsa && (
						<div
							onClick={() => this.setState({ DIPDialog: true })}
							className="flex items-center justify-between"
						>
							<FormControlLabel
								className="my-1 mx-0"
								label={
									<span
										className={`text-gray-800 break-words text-base ${
											payment.DIP === true ? 'font-bold' : 'font-normal'
										}`}
									 >
										Acepto y declaro que he leído y conozco el{' '}
										<b>
											{' '}
											Documento de Información Previa (IPID) y el Documento de
											Información del Mediador
										</b>
									</span>
								}
								control={
									<Checkbox
										name="DIP"
										required={true}
										handleClose
										className={
											payment.DIP === true ? 'checkBoxActive' : 'checkBox'
										}
										onClick={(e) =>
											this.handleCheckbox(
												'DIP',
												payment.DIP === true ? false : true
											)
										}
										checked={payment.DIP}
									/>
								}
							/>
							{isAcunsa && (
								<DownloadFileButton
									file="acunsa_dip_do"
									fileName="Documento de Información Previa (IPID)"
								/>
							)}
						</div>
					)}

					<div className="flex w-full">
						<FormControlLabel
							className="m-0 w-full"
							label={
								<span
									className={`text-gray-800 text-base ${
										payment.oath_statement === true
											? 'font-bold'
											: 'font-normal'
									}`}
								>
									Declaro bajo juramento que la información suministrada es
									exacta y verídica
								</span>
							}
							control={
								<Checkbox
									name="oath_statement"
									required={true}
									className={
										payment.oath_statement === true
											? 'checkBoxActive'
											: 'checkBox'
									}
									onClick={(e) =>
										this.handleCheckbox(
											e,
											payment.oath_statement === true ? false : true
										)
									}
									checked={payment.oath_statement}
								/>
							}
						/>
					</div>
				</div>
				{/*{this.state.openPayment && (
				<PaymentDataTDC
					open={this.state.openPayment}
					handleClose={() => this.handleClosePayment()}
					fullScreen={false}
					data={this.props}
					// paymentResponse={paymentResponse}
					// handleSubmit={handleSubmit}
					handleStateAffiliation={this.props.actions.handleStateAffiliation}
					// isocode={props.isoCode}
				/>
				)}*/}
				<TermsConditionsDialog
					open={this.state.dialog}
					handleClose={this.closeDialog}
					is_affiliation={true}
					isACUNSA={isAcunsa}
				/>

				<TermsDIPDialog
					open={this.state.DIPDialog}
					handleClose={this.closeDIPDialog}
				/>

				<DialogConfirmPhone
					open={this.state.showConfirmACUNSA}
					spinnerScreen={this.state.showSpinner}
					handleVerifyCode={(callback) =>
						this.verifyAcunsaCode(2, true, callback)
					}
					handleResendCode={(callback) =>
						this.verifyAcunsaCode(1, false, callback)
					}
					verifiedNumber={this.state.verifiedNumber}
					handleClose={() => {
						this.setState({ showConfirmACUNSA: false, verifiedNumber: '' });
					}}
					handleInputCode={this.handleVerifyCodeInput}
					countDown={60}
				/>

				{this.state.showConfirm && (
					<ConfirmDialog
						open={this.state.showConfirm}
						handleClose={() => this.setState({ showConfirm: false })}
						handleApproved={this.handleChangeCoupon}
						propsConfirm={[]}
						title="¿Estás seguro que deseas aplicar otro cupón?"
						info="Está acción eliminará el descuento del cupón existente."
					/>
				)}
				{
					<SnackBar
						openAlert={this.state.openAlert}
						messageAlert={this.state.messageAlert}
						handleClose={this.handleCloseAlert}
					/>
				}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const titular = state.affiliation.get('titular');
	return {
		titular
	};
};

const mapDispatchToProps = (dispatch) => {
	return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
