import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = (theme) => ({
	table: {
		borderCollapse: 'collapse',
		margin: '0 auto',
		textAlign: 'center',
		overflow: 'auto'
	}
});

class TermsConditionAddonLife extends Component {
	render() {
		return (
			<div className="max-w-5xl mx-auto w-full">
				<div>
					<p className="text-center my-4">
						<b>ANEXO A LOS TÉRMINOS Y CONDICIONES DEL PLAN ASISTENSI</b>
					</p>
					<p className="text-center my-4">
						<b>
							TÉRMINOS Y CONDICIONES DE LA COBERTURA DE VIDA OPCIONAL PRUEBA DO
						</b>
					</p>
					<p className="text-center my-4">
						<b>(CONTRATO ANEXO VIDA)</b>
					</p>
				</div>
			</div>
		);
	}
}

export default withMobileDialog({ breakpoint: 'xs' })(
	withStyles(styles)(TermsConditionAddonLife)
);
