import React, { Component } from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import HealthSurveyDialog from './HealthSurveyDialog';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import TermsConditionsDialogAddOn from '../terms/TermsConditionsDialogAddOn';
import { SelectCurrency } from 'components/commons';
import { formatFloat } from '../utils/formatFloat';

class AddonsCardTitular extends Component {
	state = {
		showDialog: false,
		addon: null,
		idx: null
	};

	handleAccept(addon, idx) {
		this.setState({ showDialog: true, addon, idx });
		document.body.classList.add('modal-open');
	}

	handleClose = (param, addon) => {
		const addonSend = addon ? addon : this.state.addon;
		if (param)
			this.props.handleSelectAddon(param, addonSend, this.state.addon._id);
		this.setState({ showDialog: false });
		document.body.classList.remove('modal-open');
	};

	render() {
		const { titular, handleSelectAddon, addonsList, interval, isoCode } =
			this.props;
		if (!titular.addons_titular) titular.addons_titular = [];

		return (
			<>
				{this.state.showDialog && (
					<HealthSurveyDialog
						handleClose={this.handleClose}
						titular={titular}
						addon={this.state.addon}
					/>
				)}
				{this.state.showDialog && !this.state.addon.has_survey && (
					<TermsConditionsDialogAddOn
						open={this.state.showDialog}
						handleClose={this.handleClose}
						addon={this.state.addon}
						idx={this.state.idx}
					/>
				)}
				{addonsList &&
					addonsList.length > 0 &&
					addonsList.map((addon, idx) => (
						<div
							key={idx}
							className="border border-purple-300 rounded-xl bg-white w-full p-10 my-3 block"
						>
							<div className="flex justify-between items-center flex-wrap">
								<div className="text-3xl font-bold text-purple-500">
									{addon.name}
								</div>
								<span className="flex items-center">
									<span className="font-bold text-purple-300 text-3xl">
										{isoCode && isoCode.toUpperCase()}{' '}
										{formatFloat(
											addon.payment_methods.find(
												(int) => int.recurrent_interval === interval
											)?.tariffs?.currency[isoCode]
										)}
									</span>
									<span className="ml-4">
										<SelectCurrency hideFlag={true} />
									</span>
								</span>
							</div>
							<div className="flex justify-between items-center flex-wrap sm:mt-8">
								<span className="text-base leading-relaxed text-gray-800 mt-8 sm:mt-0 whitespace-pre-wrap text-justify">
									{addon.description}
								</span>
								<div className="flex justify-between">
									<FormControlLabel
										style={{ marginRight: 65 }}
										onClick={() => {
											this.handleAccept(addon, idx);
										}}
										control={
											<Radio
												checked={Boolean(
													titular.addons_titular &&
														titular.addons_titular.length > 0 &&
														titular.addons_titular?.find(
															(a) => a?.id_addon === addon._id
														)
												)}
											/>
										}
										label={
											<span className={`text-gray-800 text-base font-bold`}>
												Si, lo quiero
											</span>
										}
										labelPlacement="end"
									/>
									<FormControlLabel
										onClick={() => {
											handleSelectAddon(false, addon, addon._id);
										}}
										control={
											<Radio
												checked={
													!Boolean(
														titular.addons_titular &&
															titular.addons_titular.find(
																(a) => a?.id_addon === addon._id
															)
													) || Boolean(titular.addons_titular.length <= 0)
												}
											/>
										}
										label={
											<span className={`text-gray-800 text-base font-bold`}>
												No
											</span>
										}
										labelPlacement="end"
									/>
								</div>
							</div>
							{titular.addons_titular &&
								titular.addons_titular.length > 0 &&
								titular.addons_titular?.find(
									(a) => a?.id_addon === addon._id
								) &&
								!titular.addons_titular.find((a) => a.id_addon === addon._id)
									?.good_health && (
									<div className="py-5">
										<WarningRoundedIcon className="text-orange-400 font-bold" />{' '}
										<span className={`text-gray-800 text-lg font-bold`}>
											{' '}
											{`Debido a su situacion de salud, podra continuar con la afiliacion SIN el servicio ${addon.name}`}{' '}
										</span>
									</div>
								)}
						</div>
					))}
			</>
		);
	}
}
export default AddonsCardTitular;
